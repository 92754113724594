import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { CSSLength } from "../css-length";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { Scrollbars } from "./scrollbars";

@component({
  name: "std-sidebar-container",
})
export class SidebarContainer extends LonaWebComponent {
  onSidebarDragStart: EmptyFunction = Constants.EMPTY_FUNCTION;
  onSidebarDragMove: (
    initialSidebarWidthPx: number,
    additionalSizePx: number
  ) => void = Constants.EMPTY_FUNCTION;
  onSidebarDragEnd: EmptyFunction = Constants.EMPTY_FUNCTION;
  onStickied: EmptyFunction = Constants.EMPTY_FUNCTION;

  private sidebarHoverTargetActive: boolean = false;
  private sidebarContainerHovered: boolean = false;
  private sidebarStickied: boolean = false;
  private focusedSet: Set<string> = new Set();

  constructor() {
    super();
    this.$("sidebar-hover-target-strip").onpointermove = () => {
      this.sidebarHoverTargetActive = true;
      this.updateSidebarState();
    };
    this.$("sidebar-hover-target-strip").onpointerleave = () => {
      this.sidebarHoverTargetActive = false;
      this.updateSidebarState();
    };
    this.$("sidebar-container").onpointermove = () => {
      this.sidebarContainerHovered = true;
      this.updateSidebarState();
    };
    this.$("sidebar-container").onpointerleave = () => {
      this.sidebarContainerHovered = false;
      this.updateSidebarState();
    };
    let initialSidebarWidthPx: Option<number>;
    GESTURE_MANAGER.addDragGesture(
      this.$("sidebar-resize-indicator"),
      "sidebar-drag",
      {
        onPointerDown: () => {
          initialSidebarWidthPx = this.sidebarWidth.resolveToPx();
          this.toggleAttribute("resizing", true);
          this.sidebarStickied = true;
          this.onSidebarDragStart();
        },
        onPointerMove: ({ progress }) => {
          this.onSidebarDragMove(initialSidebarWidthPx!, -progress.x);
        },
        onPointerUp: ({ progress }) => {
          this.onSidebarDragMove(initialSidebarWidthPx!, -progress.x);
          this.onSidebarDragEnd();
          this.toggleAttribute("resizing", false);
          this.sidebarStickied = false;
          this.updateSidebarState();
          initialSidebarWidthPx = null;
        },
      }
    );

    this.toggleAnimate(false);
    this.rebindSidebarWidth();
    $$.defer(() => this.toggleAnimate(true));
  }

  bind(
    callbacks?: Optional<{
      sidebarDraggedStart: EmptyFunction;
      sidebarDraggedMove: (intialSidebarWidthPx: number, n: number) => void;
      sidebarDraggedEnd: EmptyFunction;
      stickied: EmptyFunction;
    }>
  ) {
    this.onSidebarDragStart =
      callbacks?.sidebarDraggedStart ?? Constants.EMPTY_FUNCTION;
    this.onSidebarDragMove =
      callbacks?.sidebarDraggedMove ?? Constants.EMPTY_FUNCTION;
    this.onSidebarDragEnd =
      callbacks?.sidebarDraggedEnd ?? Constants.EMPTY_FUNCTION;
    this.onStickied = callbacks?.stickied ?? Constants.EMPTY_FUNCTION;
  }

  private updateSidebarState() {
    this.toggleAttribute("no-transition", false);
    $$.mutate(() => this.rebindSidebarWidth());
  }

  toggleFocused(id: string, force?: boolean) {
    const wasFocused = this.focusedSet.has(id);
    force = force == null ? !wasFocused : force;
    if (force) {
      this.focusedSet.add(id);
    } else {
      this.focusedSet.delete(id);
    }
    $$.mutate(() => this.rebindSidebarWidth());
  }

  toggleAnimate(force: boolean): boolean {
    return this.$("root").toggleAttribute("no-transition", !force);
  }

  toggleWidthAnimate(force: boolean): boolean {
    return this.$("root").toggleAttribute("no-width-transition", !force);
  }

  toggleTransformAnimate(force: boolean): boolean {
    return this.$("root").toggleAttribute("no-transform-transition", !force);
  }

  private stickied: boolean = false;

  toggleStickied(force: boolean = !this.stickied): boolean {
    return this.toggleAttribute("stickied", force);
  }

  private _sidebarWidth: CSSLength = CSSLength.fromPx(300);
  get sidebarWidth(): CSSLength {
    return this._sidebarWidth;
  }
  set sidebarWidth(widthWithUnit: CSSLength) {
    this._sidebarWidth = widthWithUnit;
    $$.mutate(() => this.rebindSidebarWidth());
  }

  get visibleSidebarWidth(): CSSLength {
    if (this.stickied) {
      return this.sidebarWidth;
    }
    return CSSLength.fromPx(0);
  }

  get extraStickyMargin(): CSSLength {
    return CSSLength.fromString(
      this.style.getPropertyValue("--extra-sticky-margin")
    ).expect();
  }

  private wasStickied: Option<boolean>;
  private wasActive: Option<boolean>;
  private previousResolvedSidebarWidth: Option<string>;

  private rebindSidebarWidth() {
    const active =
      this.sidebarContainerHovered ||
      this.sidebarHoverTargetActive ||
      this.sidebarStickied ||
      this.focusedSet.size > 0;
    const resolvedSidebarWidth = this._sidebarWidth.resolve();
    if (
      active == this.wasActive &&
      this.wasStickied == this.stickied &&
      this.previousResolvedSidebarWidth == resolvedSidebarWidth
    ) {
      return;
    }

    this.wasActive = active;
    this.wasStickied = this.stickied;
    const expanding =
      !this.previousResolvedSidebarWidth ||
      this.previousResolvedSidebarWidth < resolvedSidebarWidth;
    this.previousResolvedSidebarWidth = resolvedSidebarWidth;

    this.$("sidebar-inner-container__before").toggleAttribute(
      "stickied",
      this.stickied
    );

    this.$("root").toggleAttribute("active", active);
    this.$("root").toggleAttribute("stickied", this.stickied);
    this.$("root").toggleAttribute("expanding", expanding);

    if (this.stickied) {
      this.$(
        "main-content-container"
      ).style.transform = `translateX(calc(${resolvedSidebarWidth} + var(--extra-sticky-margin, 0px)))`;
      this.$("sidebar-container").style.transform = `translateX(0px)`;
    } else {
      this.$("main-content-container").style.transform = `translateX(0px)`;
      this.$(
        "sidebar-container"
      ).style.transform = `translateX(calc(-1 * ${resolvedSidebarWidth} - var(--extra-sticky-margin, 0px)))`;
    }
    if (active) {
      this.$("sidebar-container").style.transform = `translateX(0px)`;
    }

    this.$("sidebar-inner-container").style.width = resolvedSidebarWidth;
    this.$("sidebar-inner-container__before").toggleAttribute(
      "expanding",
      expanding
    );
    this.$("sidebar-inner-container__before").style.width =
      resolvedSidebarWidth;
  }

  attributeChangedCallback(
    name: string,
    _oldValue: Option<string>,
    newValue: Option<string>
  ) {
    switch (name) {
      case "stickied":
        const stickied = newValue != null;
        this.stickied = stickied;
        $$.mutate(() => this.rebindSidebarWidth());
        this.onStickied();
        break;
    }
  }

  static observedAttributes = ["stickied"];

  static $styles = [
    Scrollbars.$hideScrollbars,
    css`
      :host {
        display: block;
        position: relative;
        overflow: hidden;
        --p-animation-duration: var(
          --sidebar-container-animation-duration,
          300ms
        );
      }

      * {
        transition-timing-function: ease;
        transition-duration: var(--p-animation-duration);
      }

      #root {
        overscroll-behavior: none;
        height: 100%;
        align-items: stretch;
      }

      #root[no-transition] * {
        transition: none !important;
      }

      #main-content-container,
      #sidebar-container {
        transition-property: transform, box-shadow, background-color, width;
      }

      #sidebar-container {
        position: absolute;
        left: 0px;
        top: var(--sidebar-container-top, 0px);
        height: var(--sidebar-container-height, 100%);
        z-index: 1;
        border-top-right-radius: var(--sidebar-container-border-radius, 12px);
        border-bottom-right-radius: var(
          --sidebar-container-border-radius,
          12px
        );
        will-change: transform;
        transition: transform var(--p-animation-duration) ease;
      }

      #sidebar-container[active] {
        transform: translateX(0px);
      }

      #sidebar-inner-container__before {
        --width-transition-duration-factor: 1;
        --box-shadow-transition-duration-factor: 1;

        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        box-shadow: var(--box-shadow);
        background-color: var(--background-color);
        border-top-right-radius: var(--sidebar-container-border-radius, 12px);
        border-bottom-right-radius: var(
          --sidebar-container-border-radius,
          12px
        );
        z-index: -1;
        transition-timing-function: ease;
        transition: width
            calc(
              var(--p-animation-duration) *
                var(--width-transition-duration-factor)
            ),
          transform
            calc(
              var(--p-animation-duration) *
                var(--width-transition-duration-factor)
            ),
          box-shadow
            calc(
              var(--p-animation-duration) *
                var(--box-shadow-transition-duration-factor)
            );
      }

      #main-content-container {
        width: 100%;
        z-index: 0;
      }

      #sidebar-hover-target-strip {
        position: absolute;
        left: 0px;
        height: 100vh;
        width: 12px;
        z-index: 99999;
      }

      #sidebar-resize-strip {
        position: absolute;
        top: 0px;
        right: -8px;
        height: 100%;
        width: 16px;
        z-index: 99999;
        display: grid;
        place-items: center;
      }
      #sidebar-resize-indicator {
        height: 340px;
        width: 6px;
        border-radius: 4px;
        cursor: ew-resize;
      }
      :host([resizing]) #sidebar-resize-indicator,
      #sidebar-resize-indicator:hover {
        background-color: var(--tertiary-text-color);
      }
      #sidebar-inner-container {
        height: 100%;
        width: 300px;
      }

      #root #sidebar-inner-container__before,
      #root[stickied] #sidebar-inner-container__before {
        --width-transition-duration-factor: 1;
        --box-shadow-transition-duration-factor: 2;
        box-shadow: none;
      }

      #root[active] #sidebar-inner-container__before {
        --width-transition-duration-factor: 1;
        --box-shadow-transition-duration-factor: 0.5;
        box-shadow: var(--box-shadow);
      }

      #root[no-width-transition] #sidebar-inner-container,
      #root[no-width-transition] #sidebar-inner-container__before {
        transition-property: transform, box-shadow, background-color;
      }

      #root[stickied][active] #sidebar-inner-container__before {
        --width-transition-duration-factor: 1;
        --box-shadow-transition-duration-factor: 2;
        box-shadow: none;
      }

      #root[no-transform-transition] #main-content-container {
        transition-property: box-shadow, background-color, width;
      }
    `,
  ];

  static $html = template`
    <std-row id=root>

      <div id=sidebar-hover-target-strip></div>

      <div id=sidebar-container>
        <div id=sidebar-inner-container>
          <div id=sidebar-inner-container__before animate></div>
          <slot name=sidebar></slot>
        </div>

        <div id=sidebar-resize-strip>
          <div id=sidebar-resize-indicator></div>
        </div>
      </div>

      <div id=main-content-container>
        <slot name=main-content></slot>
      </div>
    </std-row>
  `;
}
