import { LonaWebComponent, template } from "@lona/component";
import { Column, Div } from "@lona/component-builtin";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { MathUtils } from "@lona/math";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { $jsx } from "../ui/jsx";
import { Tooltip } from "./tooltip";
import { NaiveDate } from "@lona-chrono";

@component({
  name: "std-streaks",
})
export class Streaks extends LonaWebComponent {
  bind(props: Streaks.Props) {
    DomUtils.clearChildren(this.$("streak-grid"));

    let end = props.today
      .add({ days: 31 })
      .toStartOfMonth(true)
      .add({ days: 7 });
    let start = end
      .add({
        // todo: not precise
        days: -props.durationMths * 31,
      })
      .toStartOfWeek()
      .add({
        days: -7 * 2,
      });

    let currentDay = start;
    let offset = 0;
    let previousMonth = start.month;
    let gotToday = false;
    while (currentDay.isBefore(end)) {
      const $col = Column.make();
      $$.mutate(() => this.$("streak-grid").appendChild($col));

      for (let day = 0; day < 7; ++day) {
        const $day = Div.make();
        $day.classList.add("cell");
        $col.appendChild($day);

        const value = gotToday ? 0 : props.value(currentDay);
        if (value != 0) {
          const opacity = MathUtils.lerp(0.3, 1, value / 4);
          $day.style.background = `#adb88a${toHex(opacity)}`;
        } else if (gotToday) {
          $day.style.background = "rgba(255,255,255,0.5)";
        }
        $day.setAttribute("day", String(currentDay.toString()));
        $day.setAttribute("value", String(value));
        $day.setAttribute(
          "tooltip",
          `${currentDay.dayOfWeek.label} ${currentDay.month.name} ${currentDay.day}, ${currentDay.yr}, Count: ${value}`
        );

        if (currentDay.equals(props.today)) {
          gotToday = true;
          $day.style.border = "2px solid #666";
        }

        currentDay = currentDay.add({ days: 1 });
      }

      const lastDayOfWeek = currentDay.add({ days: -1 });
      if (
        previousMonth != lastDayOfWeek.month &&
        lastDayOfWeek.month != end.month
      ) {
        previousMonth = lastDayOfWeek.month;
        this.$("months").appendChild(
          $jsx(
            "p",
            {
              className: "month",
              style: {
                transform: `translateX(${offset + 2}px)`,
              },
            },
            lastDayOfWeek.month.name
          )
        );
      }

      offset += 14;
    }
  }

  static $styles = [
    // Tooltip.$style,
    css`
      :host {
        margin-inline: 8px;
      }

      #root {
        margin-top: 4px;
      }

      .cell {
        position: relative;
        height: 12px;
        width: 12px;
        margin: 1px;
        margin-left: 0px;
        margin-right: 2px;
        border-radius: 3px;
        background: white;
      }

      #months {
        position: relative;
        margin-bottom: 8px;
        height: 12px;
      }

      .month {
        position: absolute;
        left: 0px;
        bottom: 0px;
        color: var(--tertiary-text-color);
        --font-size: var(--small-size);
      }
    `,
    css(/* css */ `
      .cell:hover::after {
        ${Tooltip.mixin}
        left: calc(100% + 8px);
        width: max-content;
        z-index: 1;
      }
    `),
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <std-row id=months>
      </std-row>
      <std-row id=streak-grid></std-row>
    </div>
  `;
}

export namespace Streaks {
  export type Props = {
    today: NaiveDate;
    durationMths: number;
    value: (date: NaiveDate) => number;
  };
}

function toHex(value: number): string {
  const clampedValue = MathUtils.clampInc(value, 0, 1);
  const scaledValue = Math.round(clampedValue * 255);
  const hexString = scaledValue.toString(16).toUpperCase().padStart(2, "0");
  return hexString;
}
