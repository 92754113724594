import "./event-details-time-label";

import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css, Style } from "../../component-styles";
import { DomUtils } from "../../dom";
import { EventDetailsTimeLabel } from "./event-details-time-label";
import { DateTime } from "@lona-chrono";
import { Button } from "../button/button";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { EditableText } from "../editable-text";
import { Typography } from "../../ui/typography";

export namespace EventDetailsContent {
  export type Props = {
    title: Option<string>;
    time: DateTime.Range;
    cta?: Option<{
      title: string;
      onAction: () => void;
    }>;
    onUpdateTime?: Option<(time: DateTime.Range) => void>;
  };
}

@component({
  name: "std-event-details-content",
})
export class EventDetailsContent extends LonaWebComponent {
  private $timeLabel: EventDetailsTimeLabel = DomUtils.hydrate(this.$("time"));
  $title: EditableText = DomUtils.hydrate(this.$("title"));

  static makeWith(props: EventDetailsContent.Props): EventDetailsContent {
    const $c = EventDetailsContent.make();
    $c.bind(props);
    return $c;
  }

  bind(props: EventDetailsContent.Props): EventDetailsContent {
    this.$title.bind(props.title, "Untitled");

    this.$timeLabel.bind(props.time);
    if (props.onUpdateTime) this.$timeLabel.onUpdateTime = props.onUpdateTime;
    if (props.cta) {
      this.$("cta-button").textContent = props.cta.title;
      GESTURE_MANAGER.addPointerEvent(this.$("cta-button"), {
        onClick: props.cta.onAction,
      });
    }
    this.$("cta").style.display = props.cta != null ? "flex" : "none";
    return this;
  }

  static $styles = [
    Button.$style,
    css`
      #title {
        margin-top: 4px;
        margin-bottom: 6px;
      }

      #cta {
        display: none;
      }

      slot::slotted(*) {
        --border-radius: 4px;
      }

      slot::slotted(*:first-child) {
        margin-top: 12px;
        --border-radius: 10px 10px 2px 2px;
      }

      slot::slotted(*:last-child) {
        --border-radius: 2px 2px 10px 10px;
      }

      slot::slotted(*:only-child) {
        --border-radius: 10px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-editable-text id=title class=h5 placeholder=Untitled></std-editable-text>
      <std-event-details-time-label id=time></std-event-details-time-label>
      <std-col id=content style=gap:2px;>
        <slot></slot>
      </std-col>
      <std-row id=cta style=justify-content:end>
        <button id=cta-button primary style=margin-top:12px;></button>
      </std-row>
    </std-col>
  `;
}

@component({
  name: "std-event-details-field",
})
class EventDetailsField<
  Content extends Option<HTMLElement> = null
> extends LonaWebComponent {
  static makeText(label: string): EventDetailsField<HTMLParagraphElement> {
    const $e = EventDetailsField.make();
    const $label = Typography.p(label, "small");
    $e.appendChild($label);
    return $e as EventDetailsField<HTMLParagraphElement>;
  }

  static makeEditable(
    text: Option<string>,
    placeholder: Option<string>
  ): EventDetailsField<EditableText> {
    const $e = EventDetailsField.make();
    const $editor = EditableText.make();
    $editor.style.width = "100%";
    $editor.bind(text, placeholder);
    $e.appendChild($editor);
    return $e as EventDetailsField<EditableText>;
  }

  // note: no type checking
  get $contentUnsafe(): Content {
    return this.children[0] as Content;
  }

  get $label(): HTMLElement {
    return this.$("label");
  }

  static $styles: Style.Compat[] = [
    css`
      #root {
        padding: 12px;
        border-radius: var(--border-radius, 4px);
        background: var(--hover-color);
      }

      #label {
        --font-size: var(--small-size);
      }

      slot::slotted(*):focus {
        outline: none;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root><slot></slot></std-row>
  `;
}

export namespace EventDetailsContent {
  export const Field = EventDetailsField;
  export type Field = EventDetailsField;
}
