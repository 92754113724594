import { EditDistance } from "../../edit-distance";
import { $$, mutate } from "../../fastdom";
import { LazySync } from "../../lazy";
import { Overlay } from "../overlay";
import { SpotlightLayout } from "./spotlight-layout";

export class Spotlight {
  private $spotlight = SpotlightLayout.make();
  private _revealed: boolean = false;
  get revealed(): boolean {
    return this._revealed;
  }

  private rows: Spotlight.Row[] = [];

  private callbacks: SpotlightLayout.Callbacks = {
    ...SpotlightLayout.NOOP_CALLBACKS,
    onSearchTextChange: (t) => {
      const searchResult = Spotlight.search(t, this.rows, () => []);
      if (searchResult.length == 0) {
        const nullResults = this.rows
          .filter((r) => r.showInNullState)
          .map((r) => ({
            node: r,
            d: 0,
          }));
        this.$spotlight.setResults(nullResults);
      } else {
        this.$spotlight.setResults(searchResult);
      }
    },
    onCommandComplete: () => {
      this.toggleReveal(false);
      $$.defer(() => {
        this.$spotlight.setResults([]);
      });
    },
  };

  private static _instance = new LazySync(() => {
    const spotlight = new Spotlight();
    return spotlight;
  });
  static get instance(): LazySync<Spotlight> {
    return this._instance;
  }

  bind(rows: Spotlight.Row[]) {
    this.rows = rows;
    this.$spotlight.setCallbacks(this.callbacks);
    this.$spotlight.reset();
  }

  @mutate
  toggleReveal(revealed: boolean = !this._revealed) {
    this._revealed = revealed;
    if (this._revealed) {
      this.$spotlight.assignStyles({
        width: "100%",
        maxWidth: "600px",
      });
      Overlay.reveal(this.$spotlight, {
        onDismiss: () => {
          this._revealed = false;
        },
        position: Overlay.Position.topPx(100),
      });
    } else {
      Overlay.dismiss(this.$spotlight);
    }
    $$.defer(() => {
      if (this._revealed) {
        this.$spotlight.bindPlaceholder("Search");
        this.$spotlight.focusSearch();
      }
    });
  }
}

export namespace Spotlight {
  export interface Row {
    priority: number;
    name: string;
    title?: HTMLElement;
    subtitle?: HTMLElement;
    icon?: Option<() => HTMLElement>;
    searchIndices: Array<string>;
    command: (args?: any) => Promise<void>;
    showInNullState?: boolean;
  }

  export interface SearchResult {
    node: Row;
    // edit-distance of the chosenText to the searchQuery
    d: number;
    chosenText?: Option<string>;
  }

  export namespace Row {
    export function toSearchResults(rows: Row[]): SearchResult[] {
      return rows.map((r) => ({
        node: r,
        d: 0,
      }));
    }
  }

  function defaultSearchResults(rows: Spotlight.Row[]) {
    const sortedNodes = rows
      .sort((a, b) => a.priority - b.priority)
      .slice(0, 2);
    return sortedNodes.map((node) => ({ node, d: 2 }));
  }

  export function search(
    searchPhrase: Option<string>,
    rows: Spotlight.Row[],
    defaultRows: (
      rows: Spotlight.Row[]
    ) => SearchResult[] = defaultSearchResults
  ): SearchResult[] {
    const sanitizedSearchPhrase = searchPhrase?.toLocaleLowerCase().trim();
    if (!sanitizedSearchPhrase || sanitizedSearchPhrase == "")
      return defaultRows(rows);

    const results: SearchResult[] = [];
    for (const node of rows) {
      const textToMatch = node.name?.toLocaleLowerCase();
      let d = EditDistance.distance(sanitizedSearchPhrase, textToMatch);
      let chosenText = textToMatch;
      for (const index of node.searchIndices) {
        const newD = EditDistance.distance(sanitizedSearchPhrase, index);
        if (newD < d) {
          d = newD;
          chosenText = index;
        }
      }
      results.push({
        node,
        d,
        chosenText,
      });
    }

    results.sort((a, b) => a.d - b.d);
    return results.slice(0, 6);
  }
}
