import { dev } from "../log";
import { MathUtils } from "../math";
import { Point } from "../point";
import { NumRange } from "../range";

export type PointsProvider = (index: number) => Point;
export type WidthsProvider = (index: number) => number;
export type DataFactory = {
  r: NumRange;
  data: PointsProvider;
};
export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type AbstractCanvasContext = CanvasCompositing &
  CanvasDrawImage &
  CanvasDrawPath &
  CanvasFillStrokeStyles &
  CanvasFilters &
  CanvasImageData &
  CanvasImageSmoothing &
  CanvasPath &
  CanvasPathDrawingStyles &
  CanvasRect &
  CanvasShadowStyles &
  CanvasState &
  CanvasText &
  CanvasTextDrawingStyles &
  CanvasTransform;

export namespace CanvasContext {
  export function roundedRect<C extends AbstractCanvasContext>(
    c: C,
    x: number,
    y: number,
    w: number,
    h: number,
    r: number
  ): C {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    if (r < 0) {
      console.log(x, y, w, h, r);
      console.trace();
      dev("HERE");
    }
    c.beginPath();
    c.moveTo(x + r, y);
    c.arcTo(x + w, y, x + w, y + h, r);
    c.arcTo(x + w, y + h, x, y + h, r);
    c.arcTo(x, y + h, x, y, r);
    c.arcTo(x, y, x + w, y, r);
    c.closePath();
    return c;
  }
}

export class LonaCanvasContext<C extends AbstractCanvasContext> {
  c: C;

  domSize: Size = { width: 0, height: 0 };
  size: Size = { width: 0, height: 0 };
  scale: number = 2;

  constructor(c: C) {
    this.c = c;
  }

  static wrap<C extends AbstractCanvasContext>(c: C): LonaCanvasContext<C> {
    return new LonaCanvasContext(c);
  }

  setDomSize(size: Size, scale: number = 2) {
    this.domSize = size;
    this.size = { width: size.width * scale, height: size.height * scale };
    this.scale = scale;
  }

  clear() {
    this.c.fillStyle = "#f8f8f8";
    this.c.fillRect(0, 0, this.size.width, this.size.height);
  }

  drawRoundedRect(x: number, y: number, w: number, h: number, r: number) {
    CanvasContext.roundedRect(this.c, x, y, w, h, r);
  }

  static buildBarChart(
    data: DataFactory,
    widthProvider: WidthsProvider,
    config: {
      paddingHorizontalPx: number;
      yRange: NumRange;
      canvasHeight: number;
      scale: number;
    }
  ): {
    index: number;
    r: Rect;
  }[] {
    const r: {
      index: number;
      r: Rect;
    }[] = [];
    const yHeight = config.yRange.end - config.yRange.start;
    const heightScale = config.canvasHeight / yHeight;
    const heightOffset = -config.yRange.start * heightScale;
    const hpadding = config.paddingHorizontalPx / config.scale;

    let offsetXSoFar = 0;
    for (let i = data.r.start; i < data.r.end; ++i) {
      const p = data.data(i);
      const y = MathUtils.clampInc(p.y, config.yRange.start, config.yRange.end);
      const scaledHeight = (y - config.yRange.start) * heightScale;
      const width = widthProvider(i);
      r.push({
        index: i,
        r: {
          x: offsetXSoFar + hpadding,
          y:
            scaledHeight > heightOffset
              ? config.canvasHeight - scaledHeight
              : heightOffset,
          width: Math.max(1, width - 2 * hpadding),
          height: Math.abs(scaledHeight - heightOffset),
        },
      });
      offsetXSoFar += width;
    }
    return r;
  }

  barChart(
    data: DataFactory,
    widthProvider: WidthsProvider,
    config: {
      paddingHorizontalPx: number;
      yRange: NumRange;
    }
  ): {
    index: number;
    r: Rect;
  }[] {
    return LonaCanvasContext.buildBarChart(data, widthProvider, {
      paddingHorizontalPx: config.paddingHorizontalPx,
      yRange: config.yRange,
      canvasHeight: this.size.height,
      scale: this.scale,
    });
  }

  pathCellBoundaries() {
    console.log("pathCellBoundaries", this.size);
    this.drawRoundedRect(2, 2, this.size.width - 4, this.size.height - 4, 20);
  }

  pathHorizontalLine(y: number) {
    this.c.beginPath();
    this.c.lineTo(0, y);
    this.c.lineTo(this.size.width, y);
  }

  pathScatterPlot(xUnitWidth: number, data: Option<Point>[], fn: () => void) {
    const resolution = 2;
    const heightPerYUnit = 0.25;
    const marginBottom = 8;
    for (const p of data) {
      if (!p) continue;
      const { x, y } = p;
      this.c.beginPath();
      this.c.ellipse(
        (x + 0.5) * xUnitWidth * resolution,
        this.size.height - (y * heightPerYUnit + marginBottom),
        4,
        4,
        2 * Math.PI,
        0,
        2 * Math.PI
      );
      fn();
    }
  }

  pathBestFitLine(xUnitWidth: number, data: Point[]) {
    const resolution = 2;
    const heightPerYUnit = 0.25;
    const marginBottom = 8;
    this.c.beginPath();
    for (const { x, y } of data) {
      this.c.lineTo(
        (x + 0.5) * xUnitWidth * resolution,
        this.size.height - (y * heightPerYUnit + marginBottom)
      );
    }
  }

  pathSmoothBestFitLine(xUnitWidth: number, data: Option<Point>[]) {
    // if (data.length < 3 || !data) {
    //   return;
    // }
    // this.beginPath();
    // const resolution = 2;
    // const transformX = (x: number): number => {
    //   return (x + 0.5) * xUnitWidth * resolution;
    // };
    // const heightPerYUnit = 0.25;
    // const marginBottom = 8;
    // const transformY = (y: number): number => {
    //   return this.size.height - (y * heightPerYUnit + marginBottom);
    // };
    // // move to the first point
    // this.moveTo(transformX(data[-2].x), transformY(data[-2].y));
    // let i = -1;
    // for (i = -1; i < data.length - 2; i++) {
    //   const p = data[i] ?? { x: i, y: 0 };
    //   const p1 = data[i + 1] ?? { x: i, y: 0 };
    //   const xc = (transformX(p.x) + transformX(p1.x)) / 2;
    //   const yc = (transformY(p.y) + transformY(p1.y)) / 2;
    //   this.quadraticCurveTo(transformX(p.x), transformY(p.y), xc, yc);
    // }
    // // curve through the last two points
    // this.quadraticCurveTo(
    //   transformX(data[i].x),
    //   transformY(data[i].y),
    //   transformX(data[i + 1].x),
    //   transformY(data[i + 1].y)
    // );
  }

  renderStroke(width: number, style: string) {
    this.c.lineWidth = width;
    this.c.strokeStyle = style;
    this.c.stroke();
  }

  renderFill(style: string) {
    this.c.fillStyle = style;
    this.c.fill();
  }
}
