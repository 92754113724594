import { LonaWebComponentClass } from "../component";
import { css, Style } from "../component-styles";
import { $qsa } from "../dom-selectors";
import { $$ } from "../fastdom";
import { dev } from "../log";

declare class DocumentPictureInPictureEvent extends Event {}

declare class DocumentPictureInPicture {
  requestWindow(
    options?: Optional<{
      disallowReturnToOpener: boolean;
      preferInitialWindowPlacement: boolean;
      height: number;
      width: number;
    }>
  ): Promise<Window>;

  addEventListener(
    type: "enter",
    listener: (e: DocumentPictureInPictureEvent) => any,
    options?: boolean | AddEventListenerOptions
  );

  onenter: (e: DocumentPictureInPictureEvent) => any;
}

// https://developer.chrome.com/docs/web-platform/document-picture-in-picture
export namespace Pip {
  const handle: Option<DocumentPictureInPicture> =
    window["documentPictureInPicture"];

  export const components: Set<LonaWebComponentClass> = new Set();

  export async function show(
    $e: HTMLElement,
    classes: LonaWebComponentClass[] = [...components.values()],
    margin: Option<string> = "20px"
  ) {
    if (!handle) return;
    const w = await handle.requestWindow({
      height: 480,
      width: 300,
    });
    w["is-pip"] = true;
    for (const c of classes) {
      w.customElements.define(c.componentName, c);
    }
    window.focus();
    $$.mutate(() => {
      // w.document.documentElement.setAttribute("theme", "dark");
      w.document.body.style.backgroundColor = "#ffffff";
      w.document.body.style.color = "#000000";
      w.document.body.style.margin = "";
      w.document.body.append(
        ...[...$qsa("link[rel=stylesheet]")].map(($link) =>
          $link.cloneNode(true)
        )
      );
      w.document.body.append(
        Style.makeWith(css`
          body {
            background: #202124;
            font-family: Helvetica, sans-serif;
          }
        `)
      );
      w.document.body.style.margin = margin ?? "";
      w.document.body.append($e);
    });
  }
}
