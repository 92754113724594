import "@lona/init";

import "./component-doc";
import "@lona/components/grid/grid-layout";

import { component } from "@lona/component-decorators";
import { LonaWebComponent, template } from "@lona/component";
import { css } from "@lona/component-styles";
import { H2 } from "@lona/component-builtin";
import { ComponentDoc } from "./component-doc";
import {
  OLD_COMPONENT_EXAMPLES,
  COMPOSED_EXAMPLES,
  COMPONENT_EXAMPLES,
} from "./examples";
import { List } from "@lona/components/list/list";
import { SidebarExpandButton } from "@lona/components/sidebar-expand-button";
import { SidebarContainer } from "@lona/components/sidebar-container";
import { Hotkeys } from "@lona/hotkeys";
import { $ } from "@lona/dom-selectors";
import { DomUtils } from "@lona/dom";
import { ListCellLayout } from "@lona/components/list/list-cell-layout";
import { GridLayout } from "@lona/components/grid/grid-layout";
import { GESTURE_MANAGER } from "@lona/gesture-manager";
import { nonnull } from "@lona/assert";
import { TypographyExample } from "@lona/ui/typography.example";
import { DocExample, StoryBook } from "@lona/ui/doc-example";
import { DarkMode } from "@lona/dark-mode";
import { Button } from "@lona/components/button/button";
import { Link } from "@lona/components/link/link";
import { IconsStoryBook } from "@lona/ui/icons.example";
import { AdminControls } from "@lona/dev/admin-controls";

Hotkeys.init([
  ["s", () => $<AppRoot>("root").toggleSidebarSticky()],
  ["d", () => DarkMode.toggle()],
  ["=", () => AdminControls.toggleReveal()],
]);

@component({
  name: "app-root",
})
export class AppRoot extends LonaWebComponent {
  private $grid = this.$<GridLayout>("doc-container");

  toggleSidebarSticky() {
    const revealed =
      this.$<SidebarExpandButton>("expand-button").toggleAttribute("revealed");
    DomUtils.flipAnimation([this.$("description")], () => {
      const $container = this.$<SidebarContainer>("sidebar-container");
      $container.toggleAttribute("no-transition", false);
      $container.toggleAttribute("stickied");
      this.toggleAttribute("revealed");
      if (revealed) {
        this.style.setProperty(
          "--sidebar-stickied-width",
          $container.sidebarWidth.resolve()
        );
        this.$("sidebar").style.maxHeight = "100%";
      } else {
        this.$("sidebar").style.removeProperty("max-height");
        this.style.setProperty("--sidebar-stickied-width", "0px");
      }
    });
  }

  connectedCallback() {
    const $rows = OLD_COMPONENT_EXAMPLES.map((e, index) => {
      const $row = ListCellLayout.makeText({
        title: e.componentName,
      });
      $row.style.cursor = "pointer";
      $row.addPointerEvent({
        onClick: () => {
          const $children = [...this.$<GridLayout>("doc-container").children];
          const $child = $children[index];
          $child.scrollIntoView({ behavior: "smooth", block: "center" });
          $child.toggleAttribute("flash", true);
          setTimeout(() => $child.removeAttribute("flash"), 800);
        },
      });
      return $row;
    });
    const $list = List.makeWith($rows);

    this.$("sidebar").appendChild($list);
    this.$<SidebarExpandButton>("expand-button").onClick = () =>
      this.toggleSidebarSticky();

    for (const $button of this.$qsa("button[data-nav]")) {
      GESTURE_MANAGER.addPointerEvent($button, {
        onClick: () => {
          this.bindGrid(
            nonnull($button.getAttribute("data-nav")) as
              | "component"
              | "example"
              | "typography"
          );
        },
      });
    }
    GESTURE_MANAGER.addPointerEvent(this.$("dark-mode-btn"), {
      onClick: () => DarkMode.toggle(),
    });

    this.bindGrid("component");
  }

  appendDocExample(example: DocExample<any>) {
    const $container = ComponentDoc.make();
    {
      const $child = example.factory();
      $container.appendChild($child);

      $child.slot = "component";
      example.bind($child, $container);
    }
    {
      const $label = H2.make();
      $label.slot = "title";
      $label.textContent = example.componentName;
      $container.appendChild($label);
    }
    this.$grid.appendChild($container);
  }

  appendStorybook(story: StoryBook) {
    for (const example of story.examples()) {
      const $container = ComponentDoc.make();
      {
        const $child = example.render();
        $child.slot = "component";
        $container.appendChild($child);
      }
      {
        const $label = H2.make();
        $label.slot = "title";
        $label.textContent = story.title();
        $container.appendChild($label);
      }
      this.$grid.appendChild($container);
    }
  }

  bindGrid(type: "component" | "example" | "typography" = "component") {
    DomUtils.clearChildren(this.$grid);
    switch (type) {
      case "component":
        {
          this.$grid.bind({
            ...GridLayout.DEFAULT_CONFIG,
            minWidthPx: 400,
          });
          COMPONENT_EXAMPLES.map(this.appendStorybook.bind(this));
          OLD_COMPONENT_EXAMPLES.map(this.appendDocExample.bind(this));
        }
        break;
      case "example":
        {
          this.$grid.bind({
            ...GridLayout.DEFAULT_CONFIG,
            minWidthPx: 800,
          });
          COMPOSED_EXAMPLES.map(this.appendDocExample.bind(this));
        }
        break;
      case "typography":
        {
          this.$grid.bind({
            ...GridLayout.DEFAULT_CONFIG,
            minWidthPx: 800,
          });

          const $child = TypographyExample();
          const $container = ComponentDoc.make();
          $container.appendChild($child);

          this.$grid.appendChild($container);
          this.appendStorybook(new IconsStoryBook());
        }
        break;
    }
  }

  static $styles = [
    Button.$style,
    Link.$style,
    css`
      :host {
        height: 100%;
      }

      #root {
        padding: 40px;
      }

      #caption {
        color: var(--secondary-text-color);
        --margin-bottom: 12px;
      }

      .section {
        align-items: start;
        justify-content: start;
        width: calc((100% - 8px) / 3);
      }

      @media (max-width: 800px) {
        #title {
          margin-bottom: 8px;
        }

        #title-section {
          flex-direction: column;
        }

        #root {
          padding: 20px;
        }

        .section {
          width: calc((100% - 8px) / 2);
        }

        #description {
          width: 100%;
          margin-bottom: 16px;
        }
      }

      p {
        max-width: 400px;
        color: var(--secondary-text-color);
      }

      #thicc-divider {
        width: 60px;
        height: 6px;
        border-radius: 4px;
        background: #444;
        margin-bottom: 60px;
      }
    `,
    css`
      #expand-button {
        position: fixed;
        top: 20px;
        left: 30px;
        z-index: 999999999;
      }

      @media (max-width: 800px) {
        #expand-button {
          left: 12px;
        }
      }

      #sidebar-container {
        --sidebar-container-top: 68px;
        --sidebar-container-height: auto;
        --sidebar-container-border-radius: 4px;

        height: 100vh;
        width: 100vw;
        overflow: scroll;
      }

      #sidebar {
        max-height: 400px;
        padding: 20px;
        overflow: scroll;
      }

      #root {
        height: 100%;
        width: calc(100% - var(--sidebar-stickied-width, 0px));
        overflow: scroll;
      }

      #doc-container {
        margin-bottom: 80px;
      }

      #footer {
        position: absolute;
        bottom: 0px;
        width: 100%;
      }

      #doc-selection button {
        border: none;
        background: none;
        cursor: pointer;
        color: var(--tertiary-text-color);
        position: relative;
        margin-right: 20px;
      }

      #doc-selection button:hover::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 6px;
        right: 6px;
        background: #888;
        height: 2px;
        border-radius: 2px;
      }

      #description {
        --line-height-ratio: 1.4;
      }

      #description > * + * {
        --margin-top: 24px;
      }

      #title {
        font-weight: 400;
      }

      button {
        max-width: 200px;
        align-self: center;
      }
    `,
  ];

  static $html = template`
    <std-sidebar-expand-button id=expand-button></std-sidebar-expand-button>

    <std-sidebar-container id="sidebar-container">
      <std-col id=sidebar slot=sidebar></std-col>

      <std-col id=root slot=main-content>
        <std-row id=title-section style=margin-top:64px;margin-bottom:20px;>
          <std-col class=section>
            <p id=caption style=margin-left:2px;>Lona</p>
            <h1 id=title>Design Library</h1>
          </std-col>
          <std-spacer></std-spacer>
          <std-col id=description class=section style=margin-top:26px;>
            <p>
              Beautifully designed components that you can copy and paste into your apps. Accessible. Customizable. Open Source.
            </p>
            <p>
              Design apps quickly and accurately by using Sketch, Photoshop, XD, and Figma templates, guides, and other resources.
            </p>
          </std-col>
        </std-row>
        <div id=thicc-divider></div>
        <std-row id=doc-selection style=margin-bottom:20px;>
          <button text data-nav=component>Components</button>
          <button text data-nav=typography>Typography</button>
          <button text data-nav=example>Examples</button>
          <std-spacer></std-spacer>
          <std-icon-button id=dark-mode-btn squarish style=margin-right:0px;>
            <svg fill="currentColor" slot=icon viewBox="0 0 16 16">
              <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
            </svg>
          </std-icon-button>
        </std-row>
        <std-grid-layout id=doc-container no-transition></std-grid-layout>
      </std-col>
    </std-sidebar-container>
  `;
}
