import { LonaWebComponent } from "../component";
import { DocExample } from "../ui/doc-example";
import { Plot } from "./plot";

const example: DocExample<Plot> = {
  factory: LonaWebComponent.asFactory(Plot),
  componentName: "Plot",
  bind: ($c: Plot) => {
    $c.style.height = "200px";
    $c.bind({
      f: {
        r: {
          start: 0,
          end: 10,
        },
        data: (index: number) => {
          return {
            x: index,
            y: (index + 1) * 50,
          };
        },
      },
      tooltipsProvider: (idx) => ({
        title: "Plot",
        date: `Index: ${idx}`,
        value: String((idx + 1) * 50),
        change: String(50),
      }),
    });
  },
};

export default example;
