export class Globals {
  static LOGGED_IN_USER_ID = "loggedInUserId";
  static IS_SERVICE_ACCOUNT = "isServiceAccount";
  static IS_BETA = "isBeta";
  static TOAST_DURATION_MS = 4_000;

  static set loggedInUserId(id: string) {
    window[Globals.LOGGED_IN_USER_ID] = id;
  }

  static get loggedInUserId(): string {
    return window[Globals.LOGGED_IN_USER_ID];
  }

  static set isServiceAccount(b: boolean) {
    window[Globals.IS_SERVICE_ACCOUNT] = b;
  }

  static get isServiceAccount(): boolean {
    return window[Globals.IS_SERVICE_ACCOUNT];
  }

  static set isBeta(beta: boolean) {
    window[Globals.IS_BETA] = beta;
  }

  static get isBeta(): boolean {
    return window[Globals.IS_BETA];
  }
}
