import { nonnull } from "../assert";
import { AVLTree } from "../avl-tree";
import { Logger } from "../log";
import { polyfill } from "../polyfill";
import { NumOptRange, NumRange } from "../range";
import { ScrollUtils } from "../scroll-utils";

type ScrollEngineConfig<T> = {
  onCreate: () => T;
  onBind: (
    $e: T,
    index: number,
    position: { scrollPositionPx: number; baseOffsetPx: number },
    widthPx: number,
    offsetChangedOnly: boolean
  ) => void;
  onRecycle: ($e: T) => void;
  onScroll?: Option<(config: { left: number; top: number }) => void>;

  onRenderedIndiciesWillChange?: Option<(r: NumRange) => void>;
  onRenderedIndiciesChanged?: Option<(r: NumRange) => void>;
  onVisibleIndiciesChanged?: Option<(r: NumRange) => void>;
  defaultCellWidthPx: number;
  cellWidthForIndex?: Option<(index: number) => Option<number>>;

  snappingEnabled: boolean;
};

type ScrollWidthUpdater<K> = ($e: K, width: number) => void;
type ScrollWidthNode<K> = {
  id: string;
  range: NumRange;
  $k: K;
  updater: ScrollWidthUpdater<K>;
};

class ScrollOffsetsByIndex {
  private offsets: number[] = [0];

  private _maxIndex: number = 0;
  get maxIndex() {
    return this._maxIndex;
  }
  get maxOffset(): number {
    return nonnull(this.offsets[this._maxIndex]);
  }

  private _minIndex: number = 0;
  get minIndex() {
    return this._minIndex;
  }
  get minOffset(): number {
    return nonnull(this.offsets[this._minIndex]);
  }

  onUpdateExistingOffset: (index: number, offset: number) => void;

  constructor(onUpdateExistingOffset: (index: number, offset: number) => void) {
    this.onUpdateExistingOffset = onUpdateExistingOffset;
  }

  setOffset(index: number, offset: number) {
    this.offsets[index] = offset;
    if (index <= this._maxIndex && index >= this._minIndex) {
      this.onUpdateExistingOffset(index, offset);
    }
    if (index > this._maxIndex) {
      this._maxIndex = index;
    }
    if (index < this._minIndex) {
      this._minIndex = index;
    }
  }

  getOffset(index: number): Option<number> {
    return this.offsets[index];
  }

  findIndexForOffsetMin(offset: number): Option<number> {
    if (offset > this.maxOffset) return null;
    if (offset < this.minOffset) return null;
    for (let i = this._minIndex; i <= this._maxIndex; ++i) {
      if (Math.floor(this.offsets[i]) - offset >= Number.EPSILON) return i - 1;
    }
    return this._maxIndex;
  }
}

class Pool<T> {
  private factory: () => T;
  private pool: T[] = [];

  constructor(factory: () => T) {
    this.factory = factory;
  }

  get(): T {
    const r = this.pool.pop();
    if (r) return r;
    return this.factory();
  }

  recycle(t: T) {
    this.pool.push(t);
  }
}

export class ScrollEngine<T> {
  static widthPx = 16777200;
  static scrollStart = ScrollEngine.widthPx / 2;
  static minRenderedCnt = 15;

  private resizeObserver: Option<ResizeObserver>;
  private size: Size = {
    width: 0,
    height: 0,
  };
  private _optimisticViewportStart: Option<number>;
  get optimisticViewportStart(): number {
    return (
      this._optimisticViewportStart ??
      nonnull(this.visibleViewportIndicies.start)
    );
  }

  private offsets: ScrollOffsetsByIndex = new ScrollOffsetsByIndex(
    (index, offset) => {
      // TODO: we're creating an O(n) copy and then finding :/
      const entries = [...this.fixedElements.entries()].filter(
        ([_, v]) => v.index == index
      );
      entries.forEach(([$e, v]) => v.onUpdate($e, Math.round(offset)));
    }
  );
  private currentRenderedIndicies: NumRange = {
    start: 0,
    end: 0,
  };
  private currentVisibleIndicies: NumRange = {
    start: 0,
    end: 0,
  };
  private renderedElements: { t: T; index: number }[] = [];
  private fixedElements: Map<
    any,
    { index: number; onUpdate: ($e: any, offset: number) => void }
  > = new Map();
  private pool: Option<Pool<T>>;
  private firstVisibleIndiciesCallback: boolean = true;
  private widthIndex: AVLTree<
    AVLTree.BucketedIntervalSearchTreeNode<Uuid, ScrollWidthNode<any>>
  > = new AVLTree();

  // Options
  private _leftOcculdedPx: number = 0;
  get leftOccludedPx(): number {
    return this._leftOcculdedPx;
  }
  set leftOccludedPx(n: number) {
    this._leftOcculdedPx = n;
  }

  private _defaultCellWidthPx: Option<number>;
  get defaultCellWidthPx(): number {
    return this._defaultCellWidthPx!;
  }
  set defaultCellWidthPx(n: number) {
    this._defaultCellWidthPx = n;
    if (!this.config) return;
    const centerIndex = this.visibleCenterIndex;
    for (let i = centerIndex + 1; i <= this.offsets.maxIndex; ++i) {
      this.offsets.setOffset(
        i,
        nonnull(this.offsets.getOffset(i - 1)) +
          this.generateCellWidthForIndex(i - 1)
      );
    }
    for (let i = centerIndex - 1; i >= this.offsets.minIndex; --i) {
      this.offsets.setOffset(
        i,
        nonnull(this.offsets.getOffset(i + 1)) -
          this.generateCellWidthForIndex(i)
      );
    }
    this.invalidateVisibleViewport();
    this.rebind(true);
  }

  snappingEnabled: boolean = true;

  // Params
  private $root: Option<HTMLElement>;
  private config: Option<ScrollEngineConfig<T>>;

  initialize($root: HTMLElement, config: ScrollEngineConfig<T>) {
    this.$root = $root;
    this.config = config;
    if (this._defaultCellWidthPx == null)
      this._defaultCellWidthPx = config.defaultCellWidthPx;
    this._contentOffset = -this._leftOcculdedPx;
    this.snappingEnabled = config.snappingEnabled;

    // 1) Update Scroll
    $root.scrollTo({
      left: ScrollEngine.scrollStart - this._leftOcculdedPx,
    });

    // 2) Add Listeners
    let firstScroll = true;
    this.resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        const r = entries[0].contentRect;
        this.didUpdateSize({ width: r.width, height: r.height });
      }
    );
    this.resizeObserver.observe($root);
    let snapTimeout: Option<number>;
    let idleTimeout: Option<number>;
    let optimisticViewportTimeout: Option<number>;
    $root.addEventListener(
      "scroll",
      () => {
        if (snapTimeout) clearTimeout(snapTimeout);
        if (idleTimeout) polyfill.cancelIdleCallback(idleTimeout);
        this.config!.onScroll &&
          this.config!.onScroll!({
            left: $root.scrollLeft,
            top: $root.scrollTop,
          });
        const newContentOffset = $root.scrollLeft - ScrollEngine.scrollStart;
        if (this.contentOffset == newContentOffset) return;
        if (!firstScroll) this.contentOffset = newContentOffset;
        firstScroll = false;

        if (optimisticViewportTimeout) clearTimeout(optimisticViewportTimeout);
        optimisticViewportTimeout = setTimeout(() => {
          optimisticViewportTimeout = null;
          this._optimisticViewportStart = null;
        }, 50);

        if (!this.snappingEnabled) return;
        snapTimeout = setTimeout(() => {
          idleTimeout = polyfill.requestIdleCallback(() => {
            if (!this.snappingEnabled) return;
            this.snapToNearestIndex("smooth", true);
            snapTimeout = null;
          });
        }, 500);
      },
      {
        passive: true,
      }
    );

    // 3) Create Pool
    this.pool = new Pool(this.config.onCreate);
  }

  /*
   API
   =============================================================================
   */
  setCellWidth(
    widthPx: number,
    options?: Optional<{
      position: "start" | "center" | "end";
      index: number;
      scrollBehavior: ScrollBehavior;
    }>
  ) {
    const position = options?.position ?? "center";
    const index = options?.index ?? nonnull(this.visibleViewportIndicies.start);

    const offset = nonnull(this.offsets.getOffset(index));
    const offsetRange = this.renderedViewportOffset;
    const offsetWidth = offsetRange.end - offsetRange.start;
    const newOffsetRange = {
      start: offset + offsetWidth / 3,
      end: offsetWidth + (2 * offsetWidth) / 3,
    };

    this.defaultCellWidthPx = widthPx;
    this.buildOffsetsForOffsetRange(newOffsetRange);
    if (this.config) {
      this.rebind(true);
      this.setIndex(index, {
        position,
        scrollBehavior: options?.scrollBehavior,
      });
    }
  }

  getContentWidthForIndex(index: number): number {
    return this.generateCellWidthForIndex(index);
  }

  setIndex(
    index: number,
    options?: Optional<{
      position: "start" | "center" | "end";
      scrollBehavior: ScrollBehavior;
      useNativeScrollTo: boolean;
      mutateEnd: boolean;
    }>
  ): number {
    Logger.info("ui")("setIndex/", index, options);

    const position = options?.position ?? "start";

    const numElementThird = Math.ceil(
      (this.currentRenderedIndicies.end - this.currentRenderedIndicies.start) /
        3
    );
    const range = {
      start: index - numElementThird,
      end: index + 2 * numElementThird,
    };
    this.buildOffsetsForIndexRange(range);

    const start =
      ScrollEngine.scrollStart +
      nonnull(this.offsets.getOffset(index)) -
      this.leftOccludedPx;

    const left = (() => {
      switch (position) {
        case "center": {
          const columnWidth = this.generateCellWidthForIndex(index);
          return Math.floor(
            start - (this.size.width - this.leftOccludedPx - columnWidth) / 2
          );
        }
        case "end":
          const columnWidth = this.generateCellWidthForIndex(index);
          return start + this.leftOccludedPx - this.size.width + columnWidth;
        case "start":
        default:
          return start;
      }
    })();

    if (options?.scrollBehavior == "instant") {
      this.$root!.scrollLeft = left;
    } else if (
      index < this.currentRenderedIndicies.start ||
      index > this.currentRenderedIndicies.end
    ) {
      // 1a) If the index is not rendered
      this.$root!.scrollTo({
        left,
        behavior: "auto",
      });
    } else if (
      options?.useNativeScrollTo ||
      options?.scrollBehavior == "auto"
    ) {
      // 1b) If using the default scroll behavior
      this.$root!.scrollTo({
        left,
        behavior: options.scrollBehavior ?? "smooth",
      });
    } else {
      // 1c) Manually scroll using recursive RAF calls
      ScrollUtils.scrollTo(this.$root!, left, 300, true);
    }
    this.invalidateVisibleViewport();
    this._optimisticViewportStart = index;

    return left;
  }

  setFixedChildIndex<K>(
    $e: K,
    index: number,
    onUpdate: ($e: K, offset: number) => void
  ): number {
    if (index > this.offsets.maxIndex) {
      this.buildOffsetsForIndexRange({
        start: this.offsets.maxIndex,
        end: index,
      });
    }
    if (index < this.offsets.minIndex) {
      this.buildOffsetsForIndexRange({
        start: index,
        end: this.offsets.minIndex,
      });
    }
    const offset = Math.round(nonnull(this.offsets.getOffset(index)));
    onUpdate($e, offset);
    this.fixedElements.set($e, {
      index,
      onUpdate,
    });
    return offset;
  }

  setFixedChildWidth<K>(
    $k: K,
    id: string,
    range: NumRange,
    onUpdate: ($e: K, width: number) => void
  ) {
    this.buildOffsetsForIndexRange({
      start: this.offsets.maxIndex,
      end: range.end + 1,
    });
    // const endOffset = this.offsets.getOffset(range.end + 1);
    onUpdate(
      $k,
      nonnull(this.offsets.getOffset(range.end + 1)) -
        nonnull(this.offsets.getOffset(range.start))
    );
    this.widthIndex.insert(
      new AVLTree.BucketedIntervalSearchTreeNode([
        {
          id,
          range,
          $k,
          updater: onUpdate,
        },
      ])
    );
  }

  rebind(widthChangeOnly: boolean) {
    for (const t of this.renderedElements) {
      this.dispatchOnBind(t.t, t.index, widthChangeOnly);
    }
  }

  rebindVisibleIndicies() {
    this.config!.onVisibleIndiciesChanged!(
      this.renderedViewportIndicies as NumRange
    );
  }

  rebindRenderedIndicies() {
    this.config!.onRenderedIndiciesWillChange &&
      this.config!.onRenderedIndiciesWillChange(
        this.renderedViewportIndicies as NumRange
      );
    this.config!.onRenderedIndiciesChanged!(
      this.renderedViewportIndicies as NumRange
    );
  }

  // rebindIndexAndResize(index: number) {
  //   for (let i = index + 1; i <= this.offsets.maxIndex; ++i) {
  //     this.offsets.setOffset(
  //       i,
  //       nonnull(this.offsets.getOffset(i - 1)) +
  //         this.generateCellWidthForIndex(i - 1)
  //     );
  //   }
  //   for (let i = index - 1; i >= this.offsets.minIndex; --i) {
  //     this.offsets.setOffset(
  //       i,
  //       nonnull(this.offsets.getOffset(i + 1)) -
  //         this.generateCellWidthForIndex(i)
  //     );
  //   }
  //   for (const t of this.renderedElements) {
  //     this.dispatchOnBind(t.t, t.index, true);
  //   }
  //   this.dispatchCellWidths();
  // }

  private dispatchCellWidths() {
    const nodes = this.widthIndex.root?.findAllOverlaps({
      start: this.offsets.minIndex,
      end: this.offsets.maxIndex,
    });
    if (nodes) {
      for (const node of nodes) {
        const endIndex = node.range.end;
        node.updater(
          node.$k,
          nonnull(this.offsets.getOffset(endIndex + 1)) -
            nonnull(this.offsets.getOffset(node.range.start))
        );
      }
    }
  }

  rebindIndex(index: number) {
    const t = this.renderedElements.find((t) => t.index == index);
    if (!t) return;
    this.dispatchOnBind(t.t, t.index);
  }

  indexForOffset(offset: number): Option<number> {
    return this.offsets.findIndexForOffsetMin(offset);
  }

  offsetForIndex(index: number): Option<number> {
    const offset = this.offsets.getOffset(index);
    if (offset === null || offset === undefined) return null;
    return Math.round(offset);
  }

  get visibleCenterOffset(): number {
    const offset = this.visibleViewportOffset;
    return (offset.start + offset.end) / 2;
  }

  get visibleCenterIndex(): number {
    return nonnull(
      this.offsets.findIndexForOffsetMin(this.visibleCenterOffset)
    );
  }

  get visibleViewportOffset(): NumRange {
    return {
      start: this._contentOffset + this.leftOccludedPx,
      end: this._contentOffset + this.size.width - this.leftOccludedPx,
    };
  }

  get visibleViewportIndicies(): NumOptRange {
    return this.indiciesFromOffset(this.visibleViewportOffset);
  }

  get renderedViewportOffset(): NumRange {
    return {
      start: this._contentOffset - this.size.width,
      end: this._contentOffset + this.size.width * 2,
    };
  }

  get renderedViewportIndicies(): NumOptRange {
    return this.indiciesFromOffset(
      this.renderedViewportOffset,
      ScrollEngine.minRenderedCnt
    );
  }

  get entries(): { t: T; index: number }[] {
    return this.renderedElements;
  }

  getEntryAtIndex(index: number): Option<{ t: T; index: number }> {
    return this.renderedElements.find((e) => e.index == index);
  }

  snapToNearestIndex(
    scrollBehavior: ScrollBehavior = "smooth",
    useNativeScrollTo: boolean = false
  ) {
    if (!this.config) return;
    const prev = nonnull(this.visibleViewportIndicies.start);
    const prevOffset = this.offsets.getOffset(prev)!;
    const currentOffset = this.offsets.getOffset(
      nonnull(this.visibleViewportIndicies.start) + 1
    )!;
    const width = currentOffset - prevOffset;
    const progress =
      (this._contentOffset + this.leftOccludedPx - prevOffset) / width;
    if (progress == 0) {
      return;
    }
    if (progress < 0.5) {
      this.setIndex(nonnull(this.visibleViewportIndicies.start), {
        scrollBehavior,
        useNativeScrollTo,
      });
    } else {
      this.setIndex(nonnull(this.visibleViewportIndicies.start) + 1, {
        scrollBehavior,
        useNativeScrollTo,
      });
    }
  }

  /*
   Callbacks
   =============================================================================
  */
  private didUpdateSize(size: Size) {
    this.size = size;
    this.invalidateVisibleViewport();
  }

  private _contentOffset: number = 0;
  private set contentOffset(contentOffset: number) {
    this._contentOffset = contentOffset;
    this.invalidateVisibleViewport();
  }

  private get contentOffset(): number {
    return this._contentOffset;
  }

  get scrollOffset(): number {
    return this._contentOffset + this._leftOcculdedPx;
  }

  private invalidateVisibleViewport() {
    this.didUpdateViewport(
      this.visibleViewportOffset,
      this.renderedViewportOffset
    );
  }

  private generateCellWidthForIndex(index: number): number {
    if (this.config!.cellWidthForIndex)
      return this.config!.cellWidthForIndex(index) ?? this._defaultCellWidthPx!;
    return this._defaultCellWidthPx!;
  }

  private buildOffsetsForOffsetRange(offsets: NumRange) {
    while (offsets.start < this.offsets.minOffset) {
      this.offsets.setOffset(
        this.offsets.minIndex - 1,
        this.offsets.minOffset -
          this.generateCellWidthForIndex(this.offsets.minIndex - 1)
      );
    }
    while (offsets.end > this.offsets.maxOffset) {
      this.offsets.setOffset(
        this.offsets.maxIndex + 1,
        this.offsets.maxOffset +
          this.generateCellWidthForIndex(this.offsets.maxIndex + 1)
      );
    }
  }

  private buildOffsetsForIndexRange(indices: NumRange) {
    while (indices.start < this.offsets.minIndex) {
      this.offsets.setOffset(
        this.offsets.minIndex - 1,
        this.offsets.minOffset -
          this.generateCellWidthForIndex(this.offsets.minIndex - 1)
      );
    }
    while (indices.end > this.offsets.maxIndex) {
      this.offsets.setOffset(
        this.offsets.maxIndex + 1,
        this.offsets.maxOffset +
          this.generateCellWidthForIndex(this.offsets.maxIndex + 1)
      );
    }
  }

  private indiciesFromOffset(
    offset: NumRange,
    minCnt: number = 0
  ): NumOptRange {
    const r = {
      start: this.offsets.findIndexForOffsetMin(Math.ceil(offset.start)),
      end: this.offsets.findIndexForOffsetMin(Math.floor(offset.end)),
    };
    // TODO: figure out min cnt
    // const cnt = r.end - r.start;
    // if (cnt < minCnt) {
    //   const left = Math.floor((minCnt - cnt) / 3);
    //   r.start -= left;
    //   r.end += minCnt - left;
    // }
    return r;
  }

  private didUpdateViewport(visibleVP: NumRange, renderedVP: NumRange) {
    const cbs: EmptyFunction[] = [];

    this.buildOffsetsForOffsetRange(renderedVP);
    const previousRenderedIndicies = this.currentRenderedIndicies;
    this.currentRenderedIndicies = this.indiciesFromOffset(
      renderedVP,
      ScrollEngine.minRenderedCnt
    ) as NumRange;
    this.config!.onRenderedIndiciesWillChange &&
      this.config!.onRenderedIndiciesWillChange(
        this.renderedViewportIndicies as NumRange
      );
    const rΔ = {
      start:
        this.currentRenderedIndicies.start - previousRenderedIndicies.start,
      end: this.currentRenderedIndicies.end - previousRenderedIndicies.end,
    };
    if (rΔ.start != 0 || rΔ.end != 0) {
      const pool = nonnull(this.pool);
      while (rΔ.start > 0) {
        // recycle from start
        // TODO: assert index
        const t = this.renderedElements.shift();
        t && pool.recycle(t.t);
        t && this.dispatchOnRecycle(t.t);
        rΔ.start -= 1;
      }
      while (rΔ.end < 0) {
        // recycle from end
        // TODO: assert index
        const t = this.renderedElements.pop();
        t && pool.recycle(t.t);
        t && this.dispatchOnRecycle(t.t);
        rΔ.end += 1;
      }

      while (rΔ.start < 0) {
        // add more to start
        const index = this.currentRenderedIndicies.start - rΔ.start;
        const t = pool.get();
        this.dispatchOnBind(t, index);
        this.renderedElements.unshift({
          t,
          index,
        });
        rΔ.start += 1;
      }
      let i = 1;
      while (rΔ.end > 0) {
        // add more to end
        const index = previousRenderedIndicies.end + i;
        const t = pool.get();
        this.dispatchOnBind(t, index);
        this.renderedElements.push({
          t,
          index,
        });
        rΔ.end -= 1;
        i += 1;
      }
      const cb = this.config!.onRenderedIndiciesChanged;
      if (cb) cbs.push(() => cb(this.currentRenderedIndicies));
    }

    const previousVisibleIndicies = this.currentVisibleIndicies;
    this.currentVisibleIndicies = this.indiciesFromOffset(
      visibleVP
    ) as NumRange;
    const vΔ = {
      start: this.currentVisibleIndicies.start - previousVisibleIndicies.start,
      end: this.currentVisibleIndicies.end - previousVisibleIndicies.end,
    };
    if (vΔ.start != 0 || vΔ.end != 0 || this.firstVisibleIndiciesCallback) {
      const cb = this.config!.onVisibleIndiciesChanged;
      if (cb) cbs.push(() => cb(this.currentVisibleIndicies));
      this.firstVisibleIndiciesCallback = false;
    }

    while (cbs.length > 0) {
      cbs.pop()!();
    }
  }

  private dispatchOnRecycle(t: T) {
    this.config!.onRecycle(t);
  }

  private dispatchOnBind(
    t: T,
    index: number,
    offsetChangedOnly: boolean = false
  ) {
    this.config!.onBind(
      t,
      index,
      {
        scrollPositionPx: this.offsets.getOffset(index)!,
        baseOffsetPx: ScrollEngine.scrollStart,
      },
      this.generateCellWidthForIndex(index),
      offsetChangedOnly
    );
  }
}
