import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { LonaIcons, PlusIcon } from "../../ui/icons";
import { Button } from "../button/button";

@component({ name: "std-sidebar-section-nav" })
export class SidebarSectionNav extends LonaWebComponent {
  bind(
    label: string,
    options?: Optional<{
      onBackButton: EmptyFunction;
      addAccessoryOnClick: EmptyFunction;
    }>
  ): SidebarSectionNav {
    this.$("new-button").style.display = options?.addAccessoryOnClick
      ? "block"
      : "none";
    options?.addAccessoryOnClick &&
      GESTURE_MANAGER.addPointerEvent(this.$("new-button"), {
        onClick: options.addAccessoryOnClick,
      });
    options?.onBackButton &&
      GESTURE_MANAGER.addPointerEvent(this.$("nav-button"), {
        onClick: options.onBackButton,
      });

    this.$("nav-title").textContent = label;
    return this;
  }

  static $styles = [
    Button.$style,
    css`
      :host {
        display: block;
        margin-bottom: 4px;
      }

      * {
        transition-property: transform, opacity;
        transition-duration: var(--p-transition-duration, 0.3s);
        transition-timing-function: ease;
      }

      #nav-button-container {
        flex-grow: 1;
      }

      #nav-button {
        width: 100%;
        align-self: stretch;
        align-items: start;
        justify-content: start;
        padding: 0px;
      }

      #back-button {
        opacity: 0;
      }

      #back-button,
      #nav-content {
        transform: translateX(-24px);
      }

      :host([back-button-shown]) #nav-content,
      :host([back-button-shown]) #back-button {
        transform: translateX(0px);
        opacity: 1;
      }

      slot[name="icon"]::slotted(*) {
        align-self: center;
        margin-left: 8px;
      }

      #new-button {
        display: none;
      }
    `,
  ];
  static $icons: Option<string[]> = [LonaIcons.symbol(PlusIcon)];
  static $html = template`
    <std-row id=root>
      <div id=nav-button-container>
        <button id="nav-button">
          <svg id=back-button style=align-self:center; viewBox="0 0 24 24" xml:space="preserve" width="24" height="24" fill="currentColor">
            <path d="M17 11H9.41l3.3-3.29a1 1 0 1 0-1.42-1.42l-5 5a1 1 0 0 0-.21.33 1 1 0 0 0 0 .76 1 1 0 0 0 .21.33l5 5a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L9.41 13H17a1 1 0 0 0 0-2z"></path>
          </svg>
          <std-row id=nav-content>
            <slot name=icon></slot>
            <h1 id=nav-title style="padding: 4px 8px; --margin-top:8px; --margin-bottom:8px; color:var(--primary-text-color); font-weight:500;" class=h6></h1>
          </std-row>
        </button>
      </div>
      <std-icon-button id=new-button style=margin-left:8px;margin-right:16px;align-self:center; squarish>
        <svg height=16 width=15>
          <use href=#plus />
        </svg>
      </std-icon-button>
    </std-row>
  `;
}
