import { DomUtils } from "../dom";
import { StoryBook } from "../ui/doc-example";
import {
  AngleRightIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  CalendarIcon,
  CheckCircleFilledIcon,
  CheckmarkIcon,
  CircleIcon,
  DragIcon,
  FormulaIcon,
  GearIcon,
  LonaIcons,
  MoonIcon,
  NumberIcon,
  PencilIcon,
  PlusIcon,
  SnapCenterIcon,
  SnapLeftIcon,
  TextIcon,
  ThreeDotsIcon,
  TrashIcon,
  XIcon,
} from "./icons";
import { $jsx } from "./jsx";

import arrowsAngleExpandIcon from "@lona/icons/arrows-angle-expand.svg";
import arrowsFullScreenIcon from "@lona/icons/arrows-full-screen.svg";
import calendarRangeIcon from "@lona/icons/calendar-range.svg";
import carFrontIcon from "@lona/icons/car-front.svg";
import checkCircleIcon from "@lona/icons/check-circle.svg";
import checkIcon from "@lona/icons/check.svg";
import cloudDrizzleIcon from "@lona/icons/cloud-drizzle.svg";
import expandArrowsVerticalIcon from "@lona/icons/expand-arrows-vertical.svg";
import eyeSlashedIcon from "@lona/icons/eye-slashed.svg";
import eyeIcon from "@lona/icons/eye.svg";
import graphIcon from "@lona/icons/graph.svg";
import gridIcon from "@lona/icons/grid.svg";
import hashIcon from "@lona/icons/hash.svg";
import journalIcon from "@lona/icons/journal.svg";
import listUlIcon from "@lona/icons/list-ul.svg";
import searchIcon from "@lona/icons/search.svg";
import snapRightIcon from "@lona/icons/snap-right.svg";
import starFilledIcon from "@lona/icons/star-filled.svg";
import starOutlineIcon from "@lona/icons/star-outline.svg";

const ALL_ICONS = [
  AngleRightIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  arrowsAngleExpandIcon,
  arrowsFullScreenIcon,
  CalendarIcon,
  calendarRangeIcon,
  carFrontIcon,
  CheckCircleFilledIcon,
  checkCircleIcon,
  checkIcon,
  CheckmarkIcon,
  CircleIcon,
  cloudDrizzleIcon,
  DragIcon,
  expandArrowsVerticalIcon,
  eyeIcon,
  eyeSlashedIcon,
  FormulaIcon,
  GearIcon,
  graphIcon,
  gridIcon,
  hashIcon,
  journalIcon,
  listUlIcon,
  MoonIcon,
  NumberIcon,
  PencilIcon,
  PlusIcon,
  searchIcon,
  SnapCenterIcon,
  SnapLeftIcon,
  snapRightIcon,
  starFilledIcon,
  starOutlineIcon,
  TextIcon,
  ThreeDotsIcon,
  TrashIcon,
  XIcon,
];

export class IconsStoryBook extends StoryBook.Default {
  title(): string {
    return "Streaks";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $container = $jsx("std-row", {
            style: {
              maxWidth: "240px",
              flexWrap: "wrap",
            },
          });
          for (const icon of ALL_ICONS) {
            $container.appendChild(
              DomUtils.assignStyles(
                LonaIcons.make(icon, {
                  sizePx: 24,
                }),
                {
                  margin: "4px",
                  background: "var(--hover-color)",
                  borderRadius: "4px",
                }
              )
            );
          }
          return $container;
        },
      },
    ];
  }
}
