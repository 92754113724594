import "@lona/components/switch";

import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css, Style } from "@lona/component-styles";
import { LazySync } from "@lona/lazy";
import { Pip } from "@lona/ui/pip";
import { DomUtils } from "../dom";
import { ButtonGroupRow } from "../components/button-group-row";
import { DevMode, DevModeUtils } from "../dev-mode";
import { AuthApi } from "../auth/auth";
import { Globals } from "../globals";

const DEV_MODE_ITEMS: ButtonGroupRow.Item<DevMode>[] = [
  {
    label: "Beta",
    identifier: "beta",
  },
  {
    label: "RC",
    identifier: "rc",
  },
  {
    label: "Prod",
    identifier: "prod",
  },
];

const LOGS: Map<string, any> = new Map();

@component({
  name: "std-admin-controls",
})
export class AdminControls extends LonaWebComponent {
  private $devModeSwitch = DomUtils.hydrate<ButtonGroupRow<DevMode>>(
    this.$("dev-mode-switch")
  );

  static _instance = new LazySync<AdminControls>(() => {
    const $a = AdminControls.make();
    $a.bind();
    return $a;
  });
  static get instance() {
    return this._instance.get();
  }

  static async toggleReveal() {
    const r = await AuthApi.get("/auth/id");
    if (!r.User || !r.User["isServiceAccount"]) return;
    Pip.show(this.instance, [], "8px");
  }

  static log(key: string, value: any, autoRevealIfAdmin: boolean = false) {
    LOGS.set(key, value);
    AdminControls.renderIfNecessary(autoRevealIfAdmin);
  }

  static clearKey(key: string, autoRevealIfAdmin: boolean = false) {
    LOGS.delete(key);
    AdminControls.renderIfNecessary(autoRevealIfAdmin);
  }

  static renderIfNecessary(autoRevealIfAdmin: boolean) {
    if (autoRevealIfAdmin) {
      this.autoRevealIfAdmin().then(() => {
        AdminControls.instance.render();
      });
    } else {
      if (!AdminControls._instance.hasResolved) return;
      AdminControls.instance.render();
    }
  }

  static async autoRevealIfAdmin() {
    if (!Globals.isServiceAccount) return;
    if (AdminControls._instance.hasResolved) return;
    await AdminControls.toggleReveal();
  }

  private bind() {
    this.$devModeSwitch.bind(DevModeUtils.getMode(), DEV_MODE_ITEMS);
  }

  private render() {
    DomUtils.clearChildren(this);
    for (const [k, v] of LOGS.entries()) {
      const $content = AdminLog.make();
      $content.textContent = `${k} ` + JSON.stringify(v, null, 2);

      this.appendChild($content);
    }
  }

  static $styles = [
    css`
      #root {
        height: 100%;
        width: 100%;
        padding: 12px;
        background: var(--background-color);
        box-shadow: var(--box-shadow);
        border-radius: 12px;
        z-index: 999999999;
      }

      slot::slotted(*) {
        margin-top: 8px;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-row style=align-items:center;>
        <p>Dev</p>
        <std-spacer></std-spacer>
        <std-button-group-row id="dev-mode-switch"></std-button-group-row>
      </std-row>
      <std-col id=logs>
        <slot></slot>
      </std-col>
    </std-col>
  `;
}

@component({
  name: "admin-log",
})
class AdminLog extends LonaWebComponent {
  static $styles: Style.Compat[] = [
    css`
      :host {
        background: var(--hover-color);
        padding: 12px;
        border-radius: 16px;
      }

      p {
        white-space: break-spaces;
        font-family: monospace, monospace;
        font-size: var(--p-size);
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <p><slot></slot></p>
  `;
}
