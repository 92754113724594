import { DocExample, StoryBook } from "@lona/ui/doc-example";

import ButtonGroupRowExample from "@lona/components/button-group-row.example";
import CarouselExample from "@lona/components/carousel.example";
import SidebarExpandButtonExample from "@lona/components/sidebar-expand-button.example";
import PromptExample from "@lona/components/prompt.example";
import IconButtonExample from "@lona/components/icon-button.example";
import DrawerExample from "@lona/components/drawer.example";
import EditableTextExample from "@lona/components/editable-text.example";
import PageIndicatorDotsExample from "@lona/components/page-indicator-dots.example";
import PopoverExample from "@lona/components/popover.example";
import ScrollExample from "@lona/components/scroll.example";
import PlotExample from "@lona/components/plot.example";
import SidebarContainerExample from "@lona/components/sidebar-container.example";
import SwitchExample from "@lona/components/switch.example";
import ContextMenuExample from "@lona/components/context-menu.example";
import PillButtonExample from "@lona/components/pill-button.example";

import ListExample from "@lona/components/list/list.example";

import SpotlightLayoutExample from "@lona/components/spotlight/spotlight-layout.example";
import AvatarExample from "@lona/components/avatar.example";
import FacepileExample from "@lona/components/facepile.example";

import { FlowContainerStoryBook } from "@lona/components/flow-container.example";
import { StreaksStoryBook } from "@lona/components/streaks.example";
import {
  SliderStoryBook,
  ThickSliderStoryBook,
} from "@lona/components/slider.example";
import { DropdownStoryBook } from "@lona/components/dropdown.example";
import { ToastStoryBook } from "@lona/components/toast/toast.example";
import { ShimmerStoryBook } from "@lona/components/shimmer.example";
import { NestedListStoryBook } from "@lona/ui/nested-list.example";
import { ProseMirrorStoryBook } from "@lona/components/prosemirror.example";
import { PipStoryBook } from "@lona/ui/pip.example";
import { DatePickerStoryBook } from "@lona/components/input/date-picker.example";
import { FormStoryBook } from "@lona/components/input/form.example";
import FlightStatusCardExample from "@lona/components/event-details/flight-status-card.example";
import { EventDetailsContentStorybook } from "@lona/components/event-details/event-details-content.example";
import EventDetailsTimeLabelExample from "@lona/components/event-details/event-details-time-label.example";

export const COMPONENT_EXAMPLES: StoryBook[] = [
  new EventDetailsContentStorybook(),
  new FormStoryBook(),
  new DatePickerStoryBook(),
  new PipStoryBook(),
  new NestedListStoryBook(),
  new DropdownStoryBook(),
  new FlowContainerStoryBook(),
  new StreaksStoryBook(),
  new SliderStoryBook(),
  new ThickSliderStoryBook(),
  new ToastStoryBook(),
  new ShimmerStoryBook(),
  new ProseMirrorStoryBook(),
];

export const OLD_COMPONENT_EXAMPLES: DocExample<any>[] = [
  FlightStatusCardExample,
  EventDetailsTimeLabelExample,
  ButtonGroupRowExample,
  CarouselExample,
  SidebarExpandButtonExample,
  PromptExample,
  IconButtonExample,
  ListExample,
  SpotlightLayoutExample,
  DrawerExample,
  EditableTextExample,
  PageIndicatorDotsExample,
  PopoverExample,
  ScrollExample,
  PlotExample,
  SidebarContainerExample,
  SwitchExample,
  ContextMenuExample,
  PillButtonExample,
  AvatarExample,
  FacepileExample,
];

import AuthPageExample from "@lona/components/pages/auth-page.example";

export const COMPOSED_EXAMPLES: DocExample<any>[] = [AuthPageExample];
