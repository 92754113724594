import { AdminControls } from "../dev/admin-controls";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { dev } from "../log";
import { Point } from "../point";
import { Utils } from "../utils";

export type ReorderManagerOnUpdatePosition<$Row> = (
  $draggedElement: $Row,
  $before: Option<$Row>,
  $after: Option<$Row>,
  $newRowOrder: $Row[]
) => boolean;

export class ReorderManager<$Row extends HTMLElement> {
  private rows: $Row[] = [];
  private originalRowOrder: $Row[] = [];
  private currentRowOrder: $Row[] = [];
  private offsets: Option<Map<$Row, DOMRect>>;
  private startPoint: Option<Point>;
  private $draggedElement: Option<$Row>;
  private draggedIndex: Option<number>;

  private $parent: Option<HTMLElement>;
  private originalParentSize: Option<{
    height: string;
    width: string;
  }>;

  private started: boolean = false;
  private gestureStart: Option<Point>;

  constructor(
    $parent: HTMLElement,
    private readonly onUpdatePosition: ReorderManagerOnUpdatePosition<$Row>
  ) {
    this.$parent = $parent;
    document.addEventListener("pointermove", (e) => {
      if (!this.started) {
        if (!this.gestureStart) return;
        if (
          !Utils.passesJitterThreshold({
            x: e.pageX - this.gestureStart!.x,
            y: e.pageY - this.gestureStart!.y,
          })
        )
          return;
        this.startGesture();
        AdminControls.log("reorder", {
          event: "pointer-down",
          start: this.startPoint,
        });
      }

      AdminControls.log("reorder", {
        event: "pointer-move",
        started: this.started,
      });
      const delta = {
        x: e.clientX - this.startPoint!.x,
        y: e.clientY - this.startPoint!.y,
      };
      this.$draggedElement!.style.removeProperty("transition");
      this.$draggedElement!.style.setProperty("--cursor-x", delta.x + "px");
      this.$draggedElement!.style.setProperty("--cursor-y", delta.y + "px");
    });
    document.addEventListener("pointerup", () => {
      if (!this.started) {
        this.gestureStart = null;
        this.startPoint = null;
        return;
      }
      this.#onPointerUp();
    });
  }

  async #onPointerUp() {
    AdminControls.log("reorder", {
      event: "pointer-up",
      started: this.started,
    });
    GESTURE_MANAGER.clearPointerCapture(this.$draggedElement!);

    $$.mutate(() => {
      this.$parent!.style.height = this.originalParentSize!.height;
      this.$parent!.style.width = this.originalParentSize!.width;
    });

    this.startPoint = null;
    this.gestureStart = null;
    this.started = false;

    const idx = this.currentRowOrder.indexOf(this.$draggedElement!);
    const $before = this.currentRowOrder[idx - 1];
    const $after = this.currentRowOrder[idx + 1];

    if (
      this.onUpdatePosition(
        this.$draggedElement!,
        $before,
        $after,
        this.currentRowOrder
      )
    ) {
      this.updateRowTransforms(true);
      this.rows = [...this.currentRowOrder];
      this.originalRowOrder = [...this.currentRowOrder];
    } else {
      this.currentRowOrder = [...this.originalRowOrder];
    }

    const baseStyles = {
      transition: "transform 0.3s ease",
      pointerEvents: "",
      "--cursor-x": "",
      "--cursor-y": "",
      position: "",
      top: "",
      left: "",
      width: "",
      zIndex: "",
    };
    for (const $child of this.currentRowOrder) {
      $$.mutate(() => {
        this.$parent?.appendChild($child);
        DomUtils.assignStyles($child, baseStyles);
      });
    }
  }

  reset() {
    // TODO: remove event listeners?
    this.rows = [];
    this.currentRowOrder = [];
    this.originalRowOrder = [];
  }

  registerRow($row: $Row) {
    this.rows.push($row);
    this.originalRowOrder.push($row);

    $row.style.transform = `translate(var(--cursor-x, 0px), calc(var(--cursor-y, 0px) + var(--offset-y, 0px)))`;
    $row.addEventListener("pointerdown", (e) => {
      this.$draggedElement = $row;
      this.gestureStart = {
        x: e.pageX,
        y: e.pageY,
      };
      this.startPoint = {
        x: e.clientX,
        y: e.clientY,
      };
    });
    $row.addEventListener("pointermove", (e) => {
      if (!this.started) {
        if (!this.gestureStart) return;
        if (
          !Utils.passesJitterThreshold({
            x: e.pageX - this.gestureStart!.x,
            y: e.pageY - this.gestureStart!.y,
          })
        )
          return;
        this.startGesture();
      }
      if (this.$draggedElement == $row) return;
      this.currentRowOrder = [];
      const r = this.offsets?.get($row);
      const draggedOverTopHalf = e.offsetY < r!.height / 2;
      // console.log(
      //   $row.getAttribute("dbg-index"),
      //   e.offsetY,
      //   r!.height / 2,
      //   draggedOverTopHalf
      // );
      for (const [ci, $child] of this.rows.entries()) {
        if (ci == this.draggedIndex) continue;
        $child.style.transition = "transform 0.3s ease";
        if ($child != $row) {
          this.currentRowOrder.push($child);
        } else if (draggedOverTopHalf) {
          this.currentRowOrder.push(this.$draggedElement!);
          this.currentRowOrder.push($child);
        } else {
          this.currentRowOrder.push($child);
          this.currentRowOrder.push(this.$draggedElement!);
        }
      }
      // dev(this.currentRowOrder.map(($r) => $r.getAttribute("dbg-index")));
      this.updateRowTransforms(false);
    });
    $row.addEventListener("pointerup", (e) => {
      if (!this.started) {
        this.gestureStart = null;
        this.startPoint = null;
        return;
      }
      this.#onPointerUp();
      e.stopPropagation();
    });
  }

  private startGesture() {
    this.started = true;

    GESTURE_MANAGER.setPointerCapture(this.$draggedElement!);
    const originalParentRect = this.$parent?.getBoundingClientRect();

    this.originalParentSize = {
      height: this.$parent!.style.height,
      width: this.$parent!.style.width,
    };
    this.$parent!.style.height = originalParentRect!.height + "px";
    this.$parent!.style.width = originalParentRect!.width + "px";

    this.currentRowOrder = [...this.rows];
    this.offsets = this.getOffsetsForRows();
    for (const [index, $child] of this.rows.entries()) {
      const isDraggedElement = $child == this.$draggedElement;
      if (isDraggedElement) this.draggedIndex = index;

      const rect = this.offsets.get($child);
      DomUtils.assignStyles($child, {
        position: "fixed",
        top: "0px",
        left: "0px",
        width: rect!.width + "px",
        zIndex: String(99999999999),
        pointerEvents: isDraggedElement ? "none" : "",
      });
      $child.setAttribute("dbg-index", String(index));
      $child.style.removeProperty("transition");
    }
    this.updateRowTransforms(true);
  }

  private updateRowTransforms(transformDragged: boolean) {
    let currentHeight = 0;
    for (const $child of this.currentRowOrder) {
      const rect = this.offsets!.get($child)!;
      if (!transformDragged && $child == this.$draggedElement) {
        currentHeight += rect.height;
        continue;
      }
      $child.style.setProperty("--offset-y", currentHeight + "px");
      currentHeight += rect.height;
    }
  }

  private getOffsetsForRows(): Map<$Row, DOMRect> {
    const map = new Map();
    this.rows.forEach(($row) => map.set($row, $row.getBoundingClientRect()));
    return map;
  }
}
