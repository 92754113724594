import { DateTime, Local, TimeUnit } from "@lona-chrono";
import { DocExample, StoryBook } from "../../ui/doc-example";
import { Popover } from "../popover";
import { EventDetailsContent } from "./event-details-content";
import { Constants } from "../../constants";

export class EventDetailsContentStorybook extends StoryBook.Default {
  title(): string {
    return "Event Details";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $c = Popover.make().assignStyles({
            "--popover-padding": "16px 12px",
          });
          const $details = EventDetailsContent.make().bind({
            title: "Brunch with Sally",
            time: defaultTimeRange(),
            onUpdateTime: Constants.EMPTY_FUNCTION,
          });
          $c.appendChild($details);
          return $c;
        },
      },
      {
        render: () => {
          const $c = Popover.make().assignStyles({
            "--popover-padding": "16px 12px",
          });
          const $details = EventDetailsContent.make().bind({
            title: "Brunch with Sally",
            time: defaultTimeRange(),
            onUpdateTime: Constants.EMPTY_FUNCTION,
            cta: {
              title: "Join Meeting",
              onAction: Constants.EMPTY_FUNCTION,
            },
          });
          $details.appendChild(
            EventDetailsContent.Field.makeText("Tue, Feb 4")
          );
          $details.appendChild(
            EventDetailsContent.Field.makeText("Does not repeat")
          );
          $details.appendChild(
            EventDetailsContent.Field.makeText("Every tuesday")
          );
          $c.appendChild($details);
          return $c;
        },
      },
    ];
  }
}

function defaultTimeRange(): DateTime.Range<Local> {
  const start = Local.now().nearest(TimeUnit.MIN15);
  return new DateTime.Range(
    start,
    start.add({
      hrs: 8,
    })
  );
}
