import { LonaWebComponent, template } from "../../component";
import { component } from "../../component-decorators";
import { css } from "../../component-styles";
import { DomUtils } from "../../dom";
import { $qsa } from "../../dom-selectors";
import { $$ } from "../../fastdom";
import { GestureManager } from "../../gesture-manager";
import {
  ReorderManager,
  ReorderManagerOnUpdatePosition,
} from "../../ui/reorder-manager";
import { ListCellLayout } from "./list-cell-layout";

@component({ name: "std-list" })
export class List extends LonaWebComponent {
  private onReorder: Option<
    ReorderManagerOnUpdatePosition<ListCellLayout<any>>
  >;
  private reorderManager = new ReorderManager<ListCellLayout>(
    this,
    ($draggedElement, $previous, $next, $newRowOrder) => {
      return this.onReorder
        ? this.onReorder($draggedElement, $previous, $next, $newRowOrder)
        : true;
    }
  );

  static makeWith(
    $cells: ListCellLayout<any>[],
    onReorder?: Option<ReorderManagerOnUpdatePosition<ListCellLayout<any>>>
  ): List {
    const $list = List.make();
    $list.bind($cells, onReorder);
    return $list;
  }

  get $rows(): HTMLElement[] {
    return [...this.children].filter(($e) => $e instanceof HTMLElement);
  }

  bind(
    $cells: ListCellLayout<any>[],
    onReorder?: Option<ReorderManagerOnUpdatePosition<ListCellLayout<any>>>
  ) {
    DomUtils.clearChildren(this);
    this.reorderManager.reset();
    this.onReorder = onReorder;
    for (const $cell of $cells) {
      onReorder && this.reorderManager.registerRow($cell);
      $$.mutate(() => {
        $cell.style.userSelect = "none";
        this.appendChild($cell);
      });
    }
  }

  bindSize(size: Option<"small" | "large" | "wide">, animate: boolean = true) {
    const $cells = [...$qsa("std-list-cell-layout", this)] as ListCellLayout[];
    const $cellContents = $cells.map(($c) => $c.$content());
    const update = () =>
      $cells.forEach(($e) =>
        size ? $e.setAttribute("size", size) : $e.removeAttribute("size")
      );
    if (animate) {
      DomUtils.flipAnimation($cellContents, update, {
        durationMs: 450,
      });
    } else {
      update();
    }
  }

  static $styles = [
    css`
      :host {
        display: block;
      }

      #root {
        position: relative;
        display: block;
        transform: translate(0px, 0px);
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <slot></slot>
    </std-col>
  `;
}

export namespace List {
  export class Builder {
    private rows: ListCellLayout<any>[] = [];

    row(
      text: string,
      options?: Optional<{
        pointerEvents?: GestureManager.PointerEvents;
      }>
    ): Builder {
      const $l = ListCellLayout.makeText({
        title: text,
      });
      if (options?.pointerEvents) {
        $l.addPointerEvent(options.pointerEvents);
      }
      $l.style.cursor = "pointer";
      this.rows.push($l);
      return this;
    }

    build(): List {
      return List.makeWith(this.rows);
    }
  }

  export function builder(): Builder {
    return new Builder();
  }
}
