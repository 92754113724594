import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { $$ } from "../fastdom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { dev } from "../log";
import { Typography } from "../ui/typography";
import { Button } from "./button/button";

type TabSelectedCallback<T extends string> = (tab: T) => void;

@component({
  name: "std-button-group-row",
})
export class ButtonGroupRow<T extends string> extends LonaWebComponent {
  onSelected: TabSelectedCallback<T> = Constants.EMPTY_FUNCTION;
  private selectedTab: Option<T>;
  private entries: ButtonGroupRow.Item<T>[] = [];
  private resizeObserver = new ResizeObserver(() =>
    this.updateSelectedTabBackground(false)
  );

  connectedCallback() {
    // NOTE: needed for PIP
    const w = this.ownerDocument.defaultView;
    w &&
      w.addEventListener("pagehide", () => {
        this.resizeObserver.disconnect();
      });
    this.resizeObserver.observe(this.$("container"));
  }

  disconnectedCallback() {
    this.resizeObserver.disconnect();
  }

  static makeWith<T extends string>(
    props: ButtonGroupRow.Props<T>
  ): ButtonGroupRow<T> {
    const $e = ButtonGroupRow.make() as unknown as ButtonGroupRow<T>;
    $e.bind(props.selected, props.entries);
    $e.onSelected = props.onSelected;
    return $e;
  }

  bindSelected(selected: T) {
    if (this.selectedTab == selected) return;

    this.selectedTab = selected;
    for (const $button of this.$qsa("button")) {
      $button.toggleAttribute("selected", false);
    }
    const $button = this.$qs(`button[tab=${selected}]`);
    $button.toggleAttribute("selected", true);
    this.selectedTab = $button.getAttribute("tab") as T;
    this.$("selected-background").toggleAttribute("no-transition", false);
    this.updateSelectedTabBackground();
    this.onSelected(this.selectedTab);
  }

  bind(selected: T, entries: ButtonGroupRow.Item<T>[]) {
    this.selectedTab = selected;
    this.entries = entries;
    for (const entry of this.entries) {
      const $button = Button.make();
      $button.setAttribute("tab", entry.identifier);
      const $text =
        typeof entry.label == "string"
          ? Typography.p(entry.label, "caption")
          : entry.label;
      $button.appendChild($text);
      $button.toggleAttribute("selected", entry.identifier == selected);
      GESTURE_MANAGER.addPointerEvent($button, {
        onClick: () => this.bindSelected(entry.identifier),
      });
      $$.mutate(() => {
        this.$("button-container").appendChild($button);
      });
    }
    $$.mutateEnd(() => {
      this.style.setProperty("--num-entries", String(this.entries.length));
      this.updateSelectedTabBackground();
    });
  }

  private updateSelectedTabBackground(animate: boolean = true) {
    const $selectedTab = this.$qs_maybe(`[tab=${this.selectedTab}]`);
    $$.measure(() => {
      if (!$selectedTab) return;
      const selectedTabRect = $selectedTab.getBoundingClientRect();
      const parentRect = this.getBoundingClientRect();
      const offset = {
        x: selectedTabRect.left - parentRect.left,
        y: selectedTabRect.top - parentRect.top,
        width: selectedTabRect.width,
      };
      $$.mutate(() =>
        DomUtils.assignStyles(this.$("selected-background"), {
          width: selectedTabRect.width - 4 + "px",
          transform: `translate(${offset.x + 2}px, 2px)`,
          display: "block",
          transition: animate ? "" : "none",
        })
      );
    });
  }

  static $styles = [
    css`
      #container {
        height: 32px;
        border-radius: 9px;
        background-color: var(--card-background-color);
        position: relative;
      }
      button {
        border: none;
        background: none;
        cursor: pointer;
        flex-basis: calc(100% / var(--num-entries));
        border-radius: 8px;
        z-index: 1;
        font-family: inherit;
        padding-inline: 12px;
      }
      #selected-background {
        position: absolute;
        height: calc(100% - 4px);
        background-color: var(--modal-background-color);
        box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 12%);
        z-index: 0;
        border-radius: 8px;
        transition: transform 0.3s ease;
        display: none;
      }
      button:hover::after {
        background-color: var(--hover-color);
      }
    `,
  ];

  static $html = template`
    <div id=container>
      <div id=selected-background></div>
      <std-row id=button-container style=height:100%;></std-row>
    </div>
  `;
}

export namespace ButtonGroupRow {
  export type Props<T extends string> = {
    selected: T;
    entries: Item<T>[];
    onSelected: TabSelectedCallback<T>;
  };

  export type Item<T extends string> = {
    label: string | HTMLElement;
    identifier: T;
  };
}
