import "./slider.test";

import { StoryBook } from "../ui/doc-example";
import { Slider } from "./slider";
import { $jsx } from "../ui/jsx";
import { range } from "@lona-chrono";

export class SliderStoryBook extends StoryBook.Default {
  title(): string {
    return "Slider";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $slider = Slider.Default.make();
          // NOTE: callers *should* *really* tell this component how wide it should be
          $slider.setBounds(
            {
              start: 100,
              end: 400,
            },
            100
          );
          $slider.style.width = "200px";
          $slider.style.maxWidth = "100%";
          return $slider;
        },
      },
    ];
  }
}

export class ThickSliderStoryBook extends StoryBook.Default {
  title(): string {
    return "Slider";
  }

  examples(): StoryBook.Example[] {
    return [
      {
        render: () => {
          const $slider = Slider.Thick.make();
          $slider.appendChild(
            $jsx(
              "p",
              {
                className: "h6",
                style: {
                  color: "var(--tertiary-text-color)",
                },
              },
              "Label"
            )
          );
          $slider.setSnap([0, 50, 100, 150, 200, 250, 300]);

          return $slider;
        },
      },
      {
        render: () => {
          const $slider = Slider.Thick.make();
          $slider.bind({
            bounds: { start: 0, end: 24 },
            value: 8,
            snap: [...range(25)],
            // renderLastSnap: true,
            renderSnaps: false,
          });
          return $slider;
        },
      },
    ];
  }
}
