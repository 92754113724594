import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css } from "../component-styles";
import { Constants } from "../constants";
import { DomUtils } from "../dom";
import { GESTURE_MANAGER } from "../gesture-manager";
import { Hotkeys } from "../hotkeys";
import { MathUtils } from "../math";

type PopoverLayoutSpec = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  transform?: string;
};

type PopoverLayoutParams = {
  spacingVerticalPx: number;
  spacingHorizontalPx: number;
  parentRect: DOMRect;
  contentRect: DOMRect;
  scrollTop: number;
};

type Positioner = (params: PopoverLayoutParams) => PopoverLayoutSpec;

export namespace PopoverPosition {
  export const TOP: Positioner = (params: PopoverLayoutParams) => ({
    top: -params.spacingVerticalPx + "px",
    left: "0px",
    transform: "translateY(-100%",
  });

  export const LEFT: Positioner = (params: PopoverLayoutParams) => ({
    top: "0px",
    right: `calc(100% + ${params.spacingHorizontalPx}px)`,
  });

  export const BOTTOM: Positioner = (params: PopoverLayoutParams) => ({
    bottom:
      -params.spacingVerticalPx -
      Math.max(
        0,
        params.parentRect.top + params.contentRect.height - window.innerHeight
      ) +
      "px",
    left: params.spacingHorizontalPx + "px",
    transform: "translateY(100%)",
  });

  export const RIGHT: Positioner = (params: PopoverLayoutParams) => ({
    top:
      (params.parentRect.top + params.contentRect.height < 0
        ? params.scrollTop
        : params.spacingVerticalPx -
          Math.max(
            0,
            params.parentRect.top +
              params.contentRect.height -
              window.innerHeight
          )) + "px",
    left: `calc(100% + ${params.spacingHorizontalPx}px)`,
  });

  export const OVERLAP: Positioner = (params: PopoverLayoutParams) => ({
    top: params.spacingVerticalPx + "px",
    left: params.spacingHorizontalPx + "px",
  });

  export const RIGHT_OVERLAP: Positioner = (params: PopoverLayoutParams) => ({
    top: params.spacingVerticalPx + "px",
    right: params.spacingHorizontalPx + "px",
  });

  export const BOTTOM_RIGHT: Positioner = (
    params: PopoverLayoutParams
  ): PopoverLayoutSpec => ({
    top: `calc(100% + ${params.spacingVerticalPx}px)`,
    right: "0px",
  });

  export const RIGHT_BOTTOM: Positioner = (
    params: PopoverLayoutParams
  ): PopoverLayoutSpec => ({
    bottom: `0px`,
    left: `calc(100% + ${params.spacingHorizontalPx}px)`,
  });
}

type RevealStickyOptions = {
  position: Positioner[];
};

namespace RevealStickyOptions {
  export const DEFAULT: RevealStickyOptions = {
    position: [PopoverPosition.RIGHT],
  };
}

@component({
  name: "std-popover",
})
export class Popover extends LonaWebComponent {
  onDismiss: EmptyFunction = Constants.EMPTY_FUNCTION;

  static calculateRemainingSpace(
    $scrollableContent: HTMLElement,
    $anchor: HTMLElement
  ): {
    top: number;
    left: number;
    right: number;
    bottom: number;
  } {
    const scrollableContentRect: DOMRect =
      $scrollableContent.getBoundingClientRect();
    const anchorRect: DOMRect = $anchor.getBoundingClientRect();
    return {
      top: anchorRect.top - scrollableContentRect.top,
      left: anchorRect.left - scrollableContentRect.left,
      right: scrollableContentRect.right - anchorRect.right,
      bottom: scrollableContentRect.bottom - anchorRect.bottom,
    };
  }

  static revealSticky(
    config: {
      $scrollableParent: HTMLElement;
      $scrollableContent: HTMLElement;
      $anchor: HTMLElement;
      $content: HTMLElement;
    },
    options: RevealStickyOptions = RevealStickyOptions.DEFAULT
  ) {
    // 1) Measure rects
    const scrollableParentRect: DOMRect =
      config.$scrollableParent.getBoundingClientRect();
    const spaceRemaining = Popover.calculateRemainingSpace(
      config.$scrollableContent,
      config.$anchor
    );
    const paddingTop =
      config.$scrollableParent.scrollTop +
      config.$scrollableContent.getBoundingClientRect().top -
      scrollableParentRect.top;

    // 2) Create popover
    const $popover = Popover.make();
    $popover.style.position = "absolute";
    $popover.style.width = "380px";
    $popover.appendChild(config.$content);
    config.$scrollableContent.appendChild($popover);

    // 3) Apply popover layout
    const specs = options.position.map((layout) =>
      layout({
        parentRect: config.$anchor.getBoundingClientRect(),
        contentRect: $popover.getBoundingClientRect(),
        spacingVerticalPx: 0,
        spacingHorizontalPx: 0,
        scrollTop: 0,
      })
    );
    const spec = specs.reduce((s, s1) => ({ ...s1, s }), {});
    $popover.style.top = spec.top ?? "";
    $popover.style.left = spec.left ?? "";
    $popover.style.bottom = spec.bottom ?? "";
    $popover.style.right = spec.right ?? "";

    // 4) Make popover sticky
    const popoverContentRect: DOMRect = $popover.getBoundingClientRect();
    const updateBounds = () => {
      const minY = config.$scrollableParent.scrollTop - paddingTop;
      const maxY =
        config.$scrollableParent.scrollTop +
        scrollableParentRect.height -
        popoverContentRect.height -
        paddingTop;
      const y = MathUtils.clampInc(spaceRemaining.top, minY, maxY);
      $popover.style.transform = `translateY(${y}px)`;
    };
    config.$scrollableParent.addEventListener("scroll", updateBounds, {
      passive: true,
    });
    updateBounds();

    // 5) Add cleanup hook
    GESTURE_MANAGER.toggleCleanUpOnClickOutside($popover, () => {
      $popover.dismiss();
      config.$scrollableParent.removeEventListener("scroll", updateBounds);
    });
  }

  static reveal(
    $anchor: HTMLElement,
    $content: HTMLElement,
    options?: {
      position?: Positioner;
      spacing?: Option<{
        vertical?: Option<number>;
        horizontal?: Option<number>;
      }>;
      scrollTop?: Option<number>;
      width?: Option<number>;
      padding?: Option<string>;
    }
  ): Popover {
    // assert(options.$anchor.style.position == "relative", "anchor position needs to be relative");
    const $popover = Popover.make();
    $popover.appendChild($content);
    $popover.assignStyles({
      position: "absolute",
      width: options?.width ? options.width + "px" : "380px",
      zIndex: String(Number.MAX_SAFE_INTEGER),
      "--popover-padding": options?.padding,
      ...(options?.position ?? PopoverPosition.RIGHT)({
        parentRect: $anchor.getBoundingClientRect(),
        contentRect: $popover.getBoundingClientRect(),
        spacingHorizontalPx: options?.spacing?.horizontal ?? 8,
        spacingVerticalPx: options?.spacing?.vertical ?? 8,
        scrollTop: options?.scrollTop ?? 0,
      }),
    });
    $anchor.appendChild($popover);
    GESTURE_MANAGER.toggleCleanUpOnClickOutside($popover, () => {
      $popover.dismiss();
    });

    Hotkeys.pushEscapeHandler(this, () => $popover.dismiss());
    return $popover;
  }

  dismiss() {
    Hotkeys.clearEscapeHandler(this);
    this.onDismiss();
    this.parentElement?.removeChild(this);
  }

  static $styles = [
    css`
      :host {
        width: 300px;
      }
      #root {
        height: inherit;
        width: inherit;
        padding: var(--popover-padding, 0px);
        border-radius: var(--popover-border-radius, 8px);
        box-shadow: var(--popover-box-shadow, var(--box-shadow));
        background-color: var(--modal-background-color);
      }
    `,
  ];

  static $html = template`
    <std-col id=root>
      <slot></slot>
    </std-col>
  `;
}
