import { nonnull } from "../assert";
import { LonaWebComponent, template } from "../component";
import { component } from "../component-decorators";
import { css, Style } from "../component-styles";
import { $$, mutate } from "../fastdom";
import { client, offset } from "../gesture-manager";
import { LazySync } from "../lazy";
import { dev } from "../log";
import { Point } from "../point";
import { NumRange } from "../range";
import {
  DataFactory,
  LonaCanvasContext,
  Rect,
  WidthsProvider,
} from "../ui/extended-canvas-context";

@component({
  name: "std-plot",
})
export class Plot extends LonaWebComponent {
  static dataRange: NumRange = {
    start: -100,
    end: 500,
  };

  private $canvas: HTMLCanvasElement = this.$<HTMLCanvasElement>("canvas");
  private ctx: LonaCanvasContext<CanvasRenderingContext2D> =
    LonaCanvasContext.wrap(nonnull(this.$canvas.getContext("2d")));
  private resizeObserver = new ResizeObserver(this.#onResize.bind(this));

  // config
  private baseColor = "#dbacff";
  private hoverColor = "#c581f8";
  private data: Option<DataFactory>;
  private yRange: NumRange = Plot.dataRange;
  private tooltipsProvider: Option<(idx: number) => PlotTooltip.Props>;

  // state
  private barChartElements: {
    index: number;
    r: Rect;
  }[] = [];
  private pendingTooltipTimeout: Option<number>;
  private lastIntersected: Option<{
    index: number;
    r: Rect;
  }>;

  constructor() {
    super();
    this.onpointermove = this.#onPointerMove.bind(this);
    this.$canvas.onpointerleave = this.#onPointerLeave.bind(this);
  }

  connectedCallback() {
    this.resizeObserver.observe(this);
  }

  #onPointerMove(e: PointerEvent) {
    const tooltipProvider = this.tooltipsProvider;
    if (!tooltipProvider) return;

    const rangeHeight = this.yRange.end - this.yRange.start;
    const heightRatio = rangeHeight / this.ctx.domSize.height;

    const p = offset(e);

    // in the range of [this.yRange.start...this.yRange.end]
    const normalizedY = this.yRange.end - p.y * heightRatio;

    const canvasRelativeY =
      ((this.yRange.end - normalizedY) / heightRatio) * this.ctx.scale;

    p.y = canvasRelativeY;
    let intersected: Option<{
      index: number;
      r: Rect;
    }>;
    for (const e of this.barChartElements) {
      if (this.intersectsRect(e.r, p)) {
        intersected = e;
        break;
      }
    }

    let $tooltip = PlotTooltip.instance.maybeGet();
    $tooltip && $tooltip.setPosition(client(e));

    /**
     * 1) Nothing is hovered
     */
    if (!this.lastIntersected && !intersected) return;

    /**
     * 2) Same thing is hovered
     */
    if (this.lastIntersected?.index == intersected?.index) return;

    /**
     * 3) Something different is hovered
     */
    if (this.lastIntersected) {
      this.drawRect(this.lastIntersected.r);
      this.ctx.renderFill(this.baseColor);
    }

    if (intersected) {
      const $tooltip = PlotTooltip.instance.get();
      $tooltip.toggleAttribute("shown", true);
      $tooltip.bind(tooltipProvider(intersected.index));
      $tooltip.setPosition(client(e));

      this.pendingTooltipTimeout && clearTimeout(this.pendingTooltipTimeout);
      this.pendingTooltipTimeout = null;

      this.drawRect(intersected.r);
      this.ctx.renderFill(this.hoverColor);
      this.lastIntersected = intersected;
    } else {
      this.lastIntersected = null;
      this.dismissTooltip();
    }
  }

  #onPointerLeave() {
    const $tooltip = PlotTooltip.instance.get();
    $tooltip.toggleAttribute("shown", false);
    this.pendingTooltipTimeout && clearTimeout(this.pendingTooltipTimeout);
    this.pendingTooltipTimeout = null;
    if (this.lastIntersected) {
      this.drawRect(this.lastIntersected.r);
      this.ctx.renderFill(this.baseColor);
    }
    this.lastIntersected = null;
  }

  #contentSize: Option<Size>;
  #resizeQueued: boolean = false;

  #onResize([e]: ResizeObserverEntry[]) {
    this.#contentSize = e.contentRect;
    if (this.#resizeQueued) return;
    this.#resizeQueued = true;
    $$.mutate(() => {
      this.#resize();
      this.#resizeQueued = false;
    });
  }

  #resize() {
    if (!this.#contentSize) return;
    this.bindDimensions(this.#contentSize);
    this.buildPoints();
    this.render();
  }

  dismissTooltip() {
    const $tooltip = PlotTooltip.instance.get();
    if (!$tooltip.hasAttribute("shown") || this.pendingTooltipTimeout) {
      return;
    }
    this.pendingTooltipTimeout = setTimeout(() => {
      $tooltip.toggleAttribute("shown", false);
      this.pendingTooltipTimeout = null;
    }, 300);
  }

  intersectsRect(r: Rect, p: Point): boolean {
    const isWithinY = p.y >= r.y && p.y <= r.y + r.height;
    const isWithinX = p.x >= r.x && p.x <= r.x + r.width;
    return isWithinX && isWithinY;
  }

  static visitGridOffsets(
    yRange: NumRange,
    canvasHeight: number,
    visitGridOffsets: (canvasOffset: number, offset: number) => void
  ) {
    const gridHeight = (yRange.end - yRange.start) / 4;
    const yHeight = yRange.end - yRange.start;
    const heightScale = canvasHeight / yHeight;
    const heightOffset = -yRange.start * heightScale;
    for (let i = 0; i < yRange.end; i += gridHeight) {
      visitGridOffsets(canvasHeight - (i * heightScale + heightOffset), i);
    }
    for (let i = 0; i > yRange.start; i -= gridHeight) {
      visitGridOffsets(canvasHeight - (i * heightScale + heightOffset), i);
    }
  }

  @mutate
  private render() {
    const ctx = this.ctx;
    const yRange = nonnull(this.yRange);

    ctx.clear();
    ctx.c.clearRect(0, 0, this.ctx.size.width, this.ctx.size.height);

    Plot.visitGridOffsets(yRange, ctx.size.height, (offset) => {
      ctx.pathHorizontalLine(offset);
      ctx.renderStroke(2, "#dadce0");
    });

    // ctx.pathCellBoundaries();
    // ctx.renderStroke(1, "blue");

    // ctx.pathHorizontalLine(ctx.size.height);
    // ctx.renderStroke(4, "#dadce0");

    // ctx.pathBestFitLine(cellWidth, points);
    // ctx.renderStroke(2, "red");

    // ctx.pathSmoothBestFitLine(cellWidth, points);
    // ctx.renderStroke(4, "#9a9cff");

    this.barChartElements.forEach((r) => {
      this.drawRect(r.r);
      if (r.index == this.lastIntersected?.index) {
        ctx.renderFill(this.hoverColor);
      } else {
        ctx.renderFill(this.baseColor);
      }
    });

    // ctx.pathScatterPlot(cellWidth, points, () => ctx.renderFill("black"));
  }

  private drawRect(r: Rect) {
    this.ctx.drawRoundedRect(
      r.x * this.ctx.scale,
      r.y,
      r.width * this.ctx.scale,
      Math.min(this.ctx.size.height, r.height),
      8
    );
  }

  /*
   Bind
   =============================================================================
  */

  bind({ f, yRange, tooltipsProvider }: Plot.Props) {
    this.data = f;
    yRange && (this.yRange = yRange);
    this.tooltipsProvider = tooltipsProvider;
    this.buildPoints();
    this.#resize();
  }

  buildPoints() {
    if (!this.data || !this.yRange || this.ctx.domSize.height == 0) {
      this.barChartElements = [];
      return;
    }
    const numElements = this.data.r.end - this.data.r.start;
    this.barChartElements = this.ctx.barChart(
      this.data!,
      () => {
        return this.ctx.domSize.width / numElements;
      },
      {
        paddingHorizontalPx: 8,
        yRange: this.yRange!,
      }
    );
  }

  recycle(): void {
    this.data = null;
  }

  bindHeight(height: number) {
    this.bindDimensions({
      width: this.ctx.domSize.width,
      height,
    });
    if (!this.data || !this.yRange || this.ctx.domSize.width == 0) return;
    this.buildPoints();
    this.render();
  }

  private bindDimensions(size: Size) {
    this.ctx.setDomSize(size);
    $$.mutate(() => {
      this.$canvas.width = this.ctx.size.width;
      this.$canvas.height = this.ctx.size.height;

      this.$canvas.style.width = this.ctx.domSize.width + "px";
      this.$canvas.style.height = this.ctx.domSize.height + "px";
    });
  }

  static $styles: Style.Compat[] = [
    css`
      :host {
        height: 200px;
      }
    `,
  ];

  static $html = template`
    <canvas id=canvas></canvas>
  `;
}

export namespace Plot {
  export type Props = {
    f: DataFactory;
    yRange?: Option<NumRange>;
    tooltipsProvider: (idx: number) => PlotTooltip.Props;
  };
}

@component({
  name: "std-plot-tooltip",
})
export class PlotTooltip extends LonaWebComponent {
  static instance = new LazySync(() => PlotTooltip.make());
  private cachedSize: Option<Size>;

  setPosition(client: Point) {
    const actual = {
      x: client.x,
      y: client.y,
    };
    if (this.cachedSize) {
      this.setBounds({
        point: actual,
        size: this.cachedSize,
      });
      return;
    }
    $$.measure(() => {
      const rect = this.getBoundingClientRect();
      this.setBounds({
        point: actual,
        size: (this.cachedSize = {
          width: rect.width,
          height: rect.height,
        }),
      });
    });
  }

  private setBounds(bounds: { point: Point; size: Size }) {
    const x = Math.min(
      bounds.point.x,
      window.innerWidth - bounds.size.width - 8
    );
    const y = Math.max(bounds.point.y - bounds.size.height - 8, 8);
    $$.mutate(() => {
      this.style.transform = `translate(${x}px, ${y}px)`;
    });
  }

  bind(props: PlotTooltip.Props) {
    this.$("label").textContent = props.title;
    this.$("date").textContent = props.date;
    this.$("value").textContent = props.value;
    this.$("change").textContent = props.change;
    $$.measure(() => {
      const rect = this.getBoundingClientRect();
      this.cachedSize = {
        width: rect.width,
        height: rect.height,
      };
      $$.mutate(() => {
        document.body.appendChild(this);
      });
    });
  }

  static $styles: Style.Compat[] = [
    css`
      :host {
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;
      }

      :host([shown]) {
        opacity: 1;
      }

      #tooltip {
        width: 220px;
        padding: 12px;
        background: white;
        border-radius: 16px;
        box-shadow: var(--box-shadow);
        pointer-events: none;
        top: 0;
        left: 0;
        font-size: 0.85rem;
      }

      #tooltip::after {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 4px;
        height: calc(100% - 16px);
        border-radius: 2px;
        background-color: var(--light-purple);
        display: none;
      }

      #label {
        --margin-top: 4px;
        --margin-bottom: 8px;
        margin-left: 4px;
      }

      #date {
        --margin-bottom: 12px;
        margin-left: 4px;
        color: var(--tertiary-text-color);
      }

      #value-container {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        background: var(--hover-color);
        padding: 16px 12px;
        height: 24px;
      }

      #change {
        color: var(--tertiary-text-color);
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=tooltip>
      <h1 class=h5 id=label>Weight</h1>
      <p id=date></p>
      <std-row id=value-container>
        <std-block style=flex-grow:1;>
          <p id=value></p>
        </std-block>
        <std-block>
          <p id=change></p>
        </std-block>
      </std-row>
    </div>
  `;
}

export namespace PlotTooltip {
  export type Props = {
    title: string;
    date: string;
    value: string;
    change: string;
  };
}
